import * as React from 'react';
import { DataGrid, GridToolbarContainer, GridToolbarColumnsButton, GridToolbarQuickFilter, GridToolbarFilterButton, GridToolbarDensitySelector } from '@mui/x-data-grid';
import { Autocomplete, Box, Button, CircularProgress, Modal, TextField, Typography } from '@mui/material';
import axios from '../../HTTP'
import { useParams } from 'react-router-dom';
import { Context } from '../../Context';

const CustomToolbar = React.memo(() => {


    return (
        <GridToolbarContainer sx={{ display: "flex", justifyContent: "space-between" }}>
            <Box display={"flex"} gap={"10px"}>
                <GridToolbarFilterButton />
                <GridToolbarDensitySelector />
                <GridToolbarColumnsButton />
                <AutocompleteComponentLogs />
            </Box>
            <GridToolbarQuickFilter />
        </GridToolbarContainer>
    );
});


function AutocompleteComponentLogs() {
    const { setFilterModel } = React.useContext(Context);
    let options = ["Employee Create", "Employee Delete", "Employee Update", "Group Create", "Group Delete", "Group Update", "Sheet Create", "Sheet Delete", "Sheet Update", "User Create", "User Delete", "User Update", "Mail Sent", "Licence Data Delete", "Login", "Logout"]
    return <Autocomplete
        sx={{ minWidth: "250px" }}
        options={options} // Use complete objects as options
        getOptionLabel={(option) => option} // Display question text
        onChange={(e, newValue) => {

            setFilterModel(prev => ({
                ...prev, items: [
                    { field: 'type', operator: 'contains', value: newValue }
                ]
            }))

        }}

        size='small'
        limitTags={2}
        renderInput={(params) => (
            <TextField
                {...params}
                label="Type"
                InputProps={{
                    ...params.InputProps,
                    type: "search",
                }}
            />
        )
        }
    />
}

const Logs = ({ }) => {
    const [logs, setLogs] = React.useState([]);
    const [rowSelectionModel, setRowSelectionModel] = React.useState([]);
    const [isLoading, setIsLoading] = React.useState(true);
    const { filterModel, setFilterModel } = React.useContext(Context);
    const columns = [
        { field: 'name', headerName: 'Name', flex: 1, minWidth: "fit-content", wrap: true },
        { field: 'email', headerName: 'Email', flex: 2, minWidth: "fit-content", wrap: true },
        { field: 'type', headerName: 'Type', flex: 1, minWidth: "fit-content", wrap: true },
        { field: 'activity', headerName: 'Activity', flex:3, minWidth: "fit-content", wrap: true },
        { field: 'timeStamp', headerName: 'Timestamp', flex: 2, minWidth: "fit-content", wrap: true },
    ];

    const rows = React.useMemo(() => {
        return logs.map((item, index) => ({
            id: item._id,
            name: item.name,
            email: item.email,
            type: item.type || "",
            activity: item.activity,
            timeStamp: new Date(item.timeStamp).toLocaleDateString("in") + ' ' + new Date(item.timeStamp).toLocaleTimeString(),
        }));
    }, [logs]);


    React.useEffect(() => {
        setIsLoading(true)
        axios.get(`api/logs`).then(res => {
            setLogs((res.data.reverse()));
            setIsLoading(false)
        })
    }, [])
    return (
        <Box sx={{ padding: "30px", display: "flex", flexDirection: "column", alignItems: "center", gap: "20px", height: "100vh" }}>

            <Typography sx={{ fontSize: "30px", fontWeight: "700" }}>Activity Logs</Typography>
            {isLoading && <CircularProgress size={150} sx={{ margin: "25vh 0" }} />}
            {
                !isLoading && (
                    <DataGrid
                        checkboxSelection={false}
                        sx={{ width: "100%" }}
                        rows={rows}
                        columns={columns}
                        pageSize={10}
                        rowsPerPageOptions={[10, 25, 50, 100]}
                        pagination
                        onRowSelectionModelChange={(newRowSelectionModel) => {
                            setRowSelectionModel(newRowSelectionModel);
                        }}
                        filterModel={filterModel}
                        disableRowSelectionOnClick
                        slots={{ toolbar: CustomToolbar }}
                        rowSelectionModel={rowSelectionModel}
                        filterPanelCss={(params) => ({
                            top: '50px', // Adjust the top value as needed
                        })}
                    />
                )}
        </Box>
    );
};

export default Logs;
