import Checkbox from '@mui/material/Checkbox';
import { Box, Button, FormControl, IconButton, InputLabel, MenuItem, Modal, Pagination, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Tooltip, Typography } from '@mui/material'
import { useContext, useEffect, useState } from 'react';
import axios from '../../../HTTP';
import { Context } from '../../../Context';
import SheetInfoCreate from './SheetInfoCreate';
import SheetInfoUpdate from './SheetInfoUpdate';
import { Navigate, useSearchParams } from 'react-router-dom';
import EditNoteIcon from '@mui/icons-material/EditNote';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DeleteIcon from '@mui/icons-material/Delete';
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
};
const tableCell = {
    border: "1px solid gray"
}

const SheetInfo = () => {
    const { apiLink, selectAll, setSelectedRows, selectedRows, ActivateToast, isAdmin, token } = useContext(Context)
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [totalCount, setTotalCount] = useState("");
    const [open, setOpen] = useState(false);
    const [sheetInfo, setSheetInfo] = useState([])
    const [isAllSelected, setIsAllSelected] = useState(false)
    const [page, setPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(40);
    const [totalPages, setTotalPages] = useState(10);
    const [searchParams, setSearchParams] = useSearchParams()
    const [keyword, setKeyword] = useState(searchParams.get("keyword") || "");

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(1);
    };
    const handleDelete = (event) => {
        const selectedRowIDs = Object.keys(selectedRows);
        setSheetInfo(sheetInfo.filter(item => !selectedRowIDs.includes(item._id)));
        for (let i in selectedRows) {
            selectedRows[i].handleDelete();
        }
        setSelectedRows([])
        setIsAllSelected(false)
        setIsDeleteModalOpen(false)
    };
    const handleUpdate = (event) => {
        if (Object.entries(selectedRows).length == 0) return ActivateToast("Please Select rows to update", "error")
        for (let i in selectedRows) {
            selectedRows[i].setIsSelected(false);
        }
        setSelectedRows([])
        setIsAllSelected(false)
        setSelectedRows({})
    };
    useEffect(() => {
        axios.get(`sheetInfo?limit=${rowsPerPage}&page=${page}&keyword=${keyword}`).then(({ data }) => {
            setSheetInfo(data.data);
            setTotalPages(data.totalPages || 1);
            setTotalCount(data.totalCount);
        })
        for (let i in selectedRows) {
            selectedRows[i].setIsSelected(false);
        }
        setSelectedRows([])
        setIsAllSelected(false)
        setSelectedRows({})
    }, [page, rowsPerPage, keyword, apiLink, setSelectedRows])
    useEffect(() => {
        if (Object.entries(selectedRows).length === sheetInfo.length) {
            setIsAllSelected(true)
        }
        else setIsAllSelected(false)
    }, [selectedRows, sheetInfo])

    if (!token) return <Navigate to={`/login`} />
    return (
        <Box p={"30px"} display={"flex"} minHeight={"calc( 100vh - 60px )"} flexDirection={"column"} justifyContent={"space-between"}>
            <Box>
                <Box display={"flex"} flexDirection={"row-reverse"} justifyContent={"space-between"} alignItems={"center"} gap={"20px"}>
                    <Button onClick={() => setOpen(true)} sx={{ height: "50px", width: "276px", padding: "10px 0", borderRadius: "20px", textTransform: "none", bgcolor: "#57B657", color: "white", "&:hover": { bgcolor: "#66d466", color: "white" } }}>
                        Add New Sheet
                    </Button>
                    <SheetInfoCreate setSheetInfo={setSheetInfo} state={[open, setOpen]} />
                </Box>
                <br />
                <Box display={"flex"} flexDirection={["column-reverse", "row", "row", "row"]} justifyContent={"space-between"} alignItems={"center"} gap={"20px"}>

                    <Box display={"flex"} alignItems={"center"} gap={"10px"}>
                        <FormControl sx={{ m: 1, minWidth: 80 }}>
                            <InputLabel id="demo-simple-select-autowidth-label">Rows Per Page</InputLabel>
                            <Select
                                labelId="demo-simple-select-autowidth-label"
                                id="demo-simple-select-autowidth"
                                value={rowsPerPage}
                                onChange={handleChangeRowsPerPage}
                                sx={{ width: "150px" }}
                                label="Rows Per Page"
                            >
                                <MenuItem value={10}>10</MenuItem>
                                <MenuItem value={40}>40</MenuItem>
                                <MenuItem value={60}>60</MenuItem>
                                <MenuItem value={80}>80</MenuItem>
                                <MenuItem value={100}>100</MenuItem>
                            </Select>
                        </FormControl>
                        {isAdmin && <Tooltip title="Delete"><Button onClick={() => {
                            if (Object.entries(selectedRows).length == 0) return ActivateToast("Please Select rows to Delete", "error")
                            setIsDeleteModalOpen(true)
                        }} sx={{ padding: "10px 25px", borderRadius: "20px", textTransform: "none", bgcolor: "#c6483f", color: "white", "&:hover": { bgcolor: "#c95f58", color: "white" } }}>
                            <DeleteIcon />
                        </Button></Tooltip>}
                    </Box>
                    <Modal
                        open={isDeleteModalOpen}
                        onClose={() => setIsDeleteModalOpen(false)}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                    >
                        <Box sx={style}>
                            <Box bgcolor={"white"} p={"20px"} borderRadius={"20px"}>
                                <Typography fontWeight={"700"} color={"gray"}>
                                    Do you want to delete these sheets ?
                                </Typography>
                                <br />
                                <Box display={"flex"} justifyContent={"space-evenly"} gap={"10px"}>
                                    <Button onClick={() => setIsDeleteModalOpen(false)} sx={{ flex: 1, padding: "10px 25px", borderRadius: "10px", textTransform: "none", bgcolor: "#008bda", color: "white", "&:hover": { bgcolor: "#0096ff", color: "white" } }}>Cancel</Button>
                                    <Button onClick={handleDelete} sx={{ flex: 1, padding: "10px 25px", borderRadius: "10px", textTransform: "none", bgcolor: "#c6483f", color: "white", "&:hover": { bgcolor: "#c95f58", color: "white" } }}>Delete</Button>
                                </Box>
                            </Box>
                        </Box>
                    </Modal>
                    <Box display={"flex"} gap={"10px"}>
                        <Button variant='contained' onClick={(e) => {
                            setKeyword("");
                            setSearchParams("")
                        }}>Reset</Button>
                        <TextField placeholder='Search' value={keyword} onChange={(e) => {
                            setKeyword(e.target.value)
                            setSearchParams({ keyword: e.target.value })
                        }} />
                    </Box>
                </Box>
                <TableContainer sx={{ width: "100%", maxHeight:"100vh" }}>
                    <Table aria-label="lead table" stickyHeader>
                        <TableHead>
                            <TableRow>

                                <TableCell sx={tableCell} ></TableCell>
                                <TableCell sx={tableCell} >Sheet ID</TableCell>
                                <TableCell sx={tableCell} >Description</TableCell>
                                <TableCell sx={tableCell} >Version</TableCell>
                                <TableCell sx={tableCell} >Update Date</TableCell>
                                {isAdmin && <TableCell sx={tableCell} >
                                    <Checkbox checked={Object.entries(selectedRows).length == sheetInfo.length} onChange={() => {
                                        setIsAllSelected(!isAllSelected);
                                        for (let i of selectAll) {
                                            if (!isAllSelected) {
                                                i.setIsSelected(true)
                                            }
                                            else {
                                                i.setIsSelected(false)
                                            }
                                        }
                                    }} />
                                </TableCell>}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                (sheetInfo || []).map((item, ind) => (
                                    <TableDataRow key={item._id} bg={ind % 2 == 0 ? "#efefef" : "white"} data={item} apiLink={apiLink} sheetInfo={sheetInfo} setSheetInfo={setSheetInfo} />
                                ))
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
                <br />
                <Box>
                    Showing {page * rowsPerPage - rowsPerPage + 1} to {page * rowsPerPage - rowsPerPage + sheetInfo.length} of {totalCount}
                </Box>
                <br />
            </Box>
            <Box display={"flex"} justifyContent={"center"}>
                <Pagination count={totalPages} page={page} onChange={handleChangePage} />
            </Box>
        </Box>
    )
}

function TableDataRow({ data, sheetInfo, setSheetInfo, bg }) {

    const [isOpen, setIsOpen] = useState(false);

    const [isSelected, setIsSelected] = useState(false)
    const [count, setCount] = useState(1);
    const { setSelectAll, setSelectedRows, isAdmin, permissions, PostToLogs } = useContext(Context);
    const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false);
    const handleUpdate = () => {
        setIsUpdateModalOpen(true)
    }
    const handleDelete = () => {
        let temp = sheetInfo.filter(item => item._id != data._id);
        setSheetInfo(temp)
        PostToLogs({
            type: "Sheet Delete",
            activity: `Sheet Deleted - ${data.description}, ${data.sheetID}`
        })
        axios.delete(`sheetInfo/${data._id}`)
    }
    useEffect(() => {
        setSelectAll(prev => [...prev, {
            setIsSelected, handleDelete
        }])
    }, [])
    useEffect(() => {
        if (count == 1) return setCount(2);
        if (isSelected) {
            setSelectedRows(prev => {
                return {
                    ...prev, [data._id]: {
                        setIsSelected, handleDelete: () => {
                            axios.delete(`sheetInfo/${data._id}`)
                        }
                    }
                }
            })
        }
        else {
            setSelectedRows(prev => {
                delete prev[data._id];
                return { ...prev };
            })
        }
    }, [isSelected])

    return (
        <TableRow sx={{ bgcolor: isSelected ? "#cae2ff" : bg }}>
            <TableCell sx={tableCell}>
                <Box display={"flex"} gap={"5px"}>
                    <Tooltip title={(isAdmin || permissions.edit) ? "Edit" : "View"}>
                        <IconButton onClick={handleUpdate} sx={{ bgcolor: "#57B657", color: "white", "&:hover": { bgcolor: "#66d466", color: "white" } }}>
                            {(isAdmin || permissions.edit) && <EditNoteIcon />}
                            {(!isAdmin && !permissions.edit) && <VisibilityIcon />}
                        </IconButton>
                    </Tooltip>
                    {isUpdateModalOpen && <SheetInfoUpdate setSheetInfo={setSheetInfo} _id={data._id} data={data} state={[isUpdateModalOpen, setIsUpdateModalOpen]} view={(!isAdmin && !permissions.edit)} />}
                    {isAdmin && <> <Tooltip title="Delete"><IconButton onClick={() => setIsOpen(true)} sx={{ bgcolor: "#c6483f", color: "white", "&:hover": { bgcolor: "#c95f58", color: "white" } }}>
                        <DeleteIcon />
                    </IconButton>
                    </Tooltip>
                        <Modal
                            open={isOpen}
                            onClose={() => setIsOpen(false)}
                            aria-labelledby="modal-modal-title"
                            aria-describedby="modal-modal-description"
                        >
                            <Box sx={style}>
                                <Box bgcolor={"white"} p={"20px"} borderRadius={"20px"}>
                                    <Typography fontWeight={"700"} color={"gray"}>
                                        Do you want to delete this sheet ?
                                    </Typography>
                                    <br />
                                    <Box display={"flex"} justifyContent={"space-evenly"} gap={"10px"}>
                                        <Button onClick={() => setIsOpen(false)} sx={{ flex: 1, padding: "10px 25px", borderRadius: "10px", textTransform: "none", bgcolor: "#008bda", color: "white", "&:hover": { bgcolor: "#0096ff", color: "white" } }}>Cancel</Button>
                                        <Button onClick={handleDelete} sx={{ flex: 1, padding: "10px 25px", borderRadius: "10px", textTransform: "none", bgcolor: "#c6483f", color: "white", "&:hover": { bgcolor: "#c95f58", color: "white" } }}>Delete</Button>
                                    </Box>
                                </Box>
                            </Box>
                        </Modal>
                    </>
                    }

                </Box>
            </TableCell>
            <TableCell sx={tableCell}>{data?.sheetID}</TableCell>
            <TableCell sx={tableCell}>{data?.description}</TableCell>
            <TableCell sx={tableCell}>{data?.version}</TableCell>
            <TableCell sx={tableCell}>{data?.updateDate}</TableCell>
            {isAdmin && <TableCell sx={tableCell}>
                <Checkbox checked={isSelected} onChange={() => setIsSelected(!isSelected)} />
            </TableCell>}
        </TableRow>
    )
}

export default SheetInfo;