import { Autocomplete, Box, Button, Container, FormControl, IconButton, InputLabel, MenuItem, Modal, Select, Slider, TextField, Typography } from '@mui/material'
import React, { useContext, useEffect, useLayoutEffect, useState } from 'react'
import axios from "../HTTP"

import { DataGrid } from '@mui/x-data-grid';
import ReplayIcon from '@mui/icons-material/Replay';
import EditNoteIcon from '@mui/icons-material/EditNote';
import DateRange from './DateRange';
import { Navigate, useNavigate } from 'react-router-dom';
import { Context } from '../Context';
// import EditNoteIcon from '@mui/icons-material/EditNote';
import Papa from 'papaparse';

const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    background: 'white',
    padding: "20px",
    boxShadow: 24,
    p: 4,
    borderRadius: "20px"
};

const exportToCSV = (data, fileName) => {
    const csv = Papa.unparse(data);
    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    const url = URL.createObjectURL(blob);
    link.href = url;
    link.setAttribute('download', fileName || 'export.csv');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
};


function Groups() {
    const nav = useNavigate()
    const { token, isAdmin } = useContext(Context);
    const [data, setData] = useState([])
    const [filteredData, setFilteredData] = useState([])
    const [options, setOptions] = useState([])
    const [selectionModel, setSelectionModel] = React.useState([]);

    const [filters, setFilters] = React.useState([{ name: "name", label: "Name", option: options.name || [] }, { name: "email", label: "Email", option: options.email || [] }, { name: "companyName", label: "Company Name", option: options.companyName || [] }, { name: "groupName", label: "Group Name", option: options.groupName || [] }]);
    const [filterModel, setFilterModel] = React.useState({
        items: [],
    });
    const [isLoading, setIsLoading] = React.useState(true);
    const [minMax, setMinMax] = useState([0, new Date().getTime() / 1000]);
    const [sliderVal, setSliderVal] = useState([0, new Date().getTime() / 1000]);
    const RenderNormalText = (params) => (
        <Box >{params.value}</Box>
    );

    const columns = [
        {
            field: '', headerName: 'Edit', width: 60, renderCell: (params) => (
                <IconButton sx={{ bgcolor: "lightgreen" }} onClick={() => {
                    // window.open("http://localhost"+ (isAdmin ? "/admin" : "/client") + `/usersInfo?keyword=${params.row.email}&editModal=${true}`)
                    nav((isAdmin ? "/admin" : "/client") + `/usersInfo?keyword=${params.row.email}&editModal=${true}`)
                }}>
                    <EditNoteIcon />
                </IconButton>
            )
        },
        { field: 'name', headerName: 'Name', width: 180, flex: 1, sortable: true, renderCell: RenderNormalText },
        { field: 'email', headerName: 'Email', width: 250, flex: 1, sortable: true, renderCell: RenderNormalText },
        { field: 'mobile', headerName: 'Mobile', width: 150, flex: 1, sortable: true, renderCell: RenderNormalText },
        { field: 'groupName', headerName: 'Group Name', width: 150, flex: 1, sortable: true, renderCell: RenderNormalText },
        { field: 'status', headerName: 'Status', width: 100, flex: 1, sortable: true, renderCell: RenderNormalText },
        { field: 'supportExpiry', headerName: 'Support Expiry', width: 100, flex: 1, sortable: true, renderCell: RenderNormalText },
        {
            field: 'startDate', headerName: 'Start Date', width: 100, flex: 1, sortable: true, renderCell: (params) => (
                <Box >{new Date(params.value).toLocaleDateString("in")}</Box>
            )
        },
        {
            field: 'endDate', headerName: 'End Date', width: 100, flex: 1, sortable: true, renderCell: (params) => (
                <Box >{new Date(params.value).toLocaleDateString("in")}</Box>
            )
        },
        { field: 'companyName', headerName: 'Company', width: 250, flex: 1, sortable: true, renderCell: RenderNormalText },
        {
            field: 'whatsAppUsers', headerName: 'WhatsApp Users', width: 300, flex: 1, sortable: true, renderCell: (params) => {
                let arr = Array.isArray(params.value) ? params.value : params.value.split(",");
                return (
                    <Box >{(arr || []).join(", ")}</Box>
                )
            }
        },
        {
            field: 'domains', headerName: 'Domains', width: 300, flex: 1, sortable: true, renderCell: (params) => {
                let arr = Array.isArray(params.value) ? params.value : params.value.split(",");
                return (
                    <Box >{(arr || []).join(", ")}</Box>
                )
            }
        },
    ];


    const handleResetFilter = () => {
        setIsLoading(true)
        axios.get(`getClientReport`).then((res) => {
            setData(res.data.data);
            setOptions(res.data.options);
            setSliderVal([new Date(res.data.dateFilter.minStartDate).getTime(), new Date(res.data.dateFilter.maxEndDate).getTime() / 1000])
            setMinMax([new Date(res.data.dateFilter.minStartDate).getTime(), new Date(res.data.dateFilter.maxEndDate).getTime() / 1000])
            setFilters([{ name: "name", label: "Name", width: 150, option: res.data.options.name || [] }, { name: "email", label: "Email", width: 240, option: res.data.options.email || [] }, { name: "companyName", label: "Company Name", width: 240, option: res.data.options.companyName || [] }, { name: "groupName", label: "Group Name", width: 200, option: res.data.options.groupName || [] }])
            setIsLoading(false);
        });
        setFilterModel({ items: [] });
    };


    useEffect(() => {
        if (!token) return
        setIsLoading(true)
        axios.get(`getClientReport`).then((res) => {
            setData(res.data.data);
            setOptions(res.data.options);
            setMinMax([new Date(res.data.dateFilter.minStartDate).getTime(), new Date(res.data.dateFilter.maxEndDate).getTime() / 1000])
            setSliderVal([new Date(res.data.dateFilter.minStartDate).getTime(), new Date(res.data.dateFilter.maxEndDate).getTime() / 1000])
            setIsLoading(false);
            setFilters([{ name: "name", label: "Name", width: 150, option: res.data.options.name || [] }, { name: "email", label: "Email", width: 240, option: res.data.options.email || [] }, { name: "companyName", label: "Company Name", width: 240, option: res.data.options.companyName || [] }, { name: "groupName", label: "Group Name", width: 200, option: res.data.options.groupName || [] }])
        });
    }, [])


    useEffect(() => {
        setFilteredData(data.filter(item => {
            let tempStartDate = new Date(item.startDate)
            let tempEndDate = new Date(item.endDate)
            // console.log(new Date(sliderVal[0] * 1000))
            return tempStartDate > new Date(sliderVal[0] * 1000) && tempEndDate < new Date(sliderVal[1] * 1000)
        }))
    }, [data, sliderVal])

    const handleExportCSV = async () => {
        // let { data } = await axios.get(`expireReportCSV`, token)
        exportToCSV(data, 'ExpiryReport.csv');
    };

    if (!token) return <Navigate to={`/login`} />
    return (
        <Box padding={"20px"} display={"flex"} flexDirection={"column"} alignItems={"center"}>
            <Box padding={"10px"} display={"flex"} width={"100%"} justifyContent={["flex-start", "flex-start", "space-between", "space-between"]}>
                <Box display={"flex"} gap={"10px"} flexWrap={"wrap"}>
                    <Button onClick={handleResetFilter} sx={{ bgcolor: "#454545", color: "#d6d6d6", "&:hover": { bgcolor: "#454545", color: "white" } }} variant="contained" color="primary">
                        <ReplayIcon />
                    </Button>
                    {
                        !isLoading && filters.map(item => (
                            // <TextField size='small' label={"Search " + item.label} onChange={(e) => {
                            //     setFilterModel(prev => ({
                            //         ...prev, items: [
                            //             { field: item.name, operator: 'contains', value: e.target.value }
                            //         ]
                            //     }))
                            // }} />
                            <Autocomplete
                                size='small'
                                disablePortal
                                id="combo-box-demo"
                                options={item.option}
                                sx={{ width: item.width || 300 }}
                                onChange={(e, val) => {
                                    setFilterModel(prev => ({
                                        ...prev, items: [
                                            { field: item.name, operator: 'contains', value: val }
                                        ]
                                    }))
                                }}
                                renderInput={(params) => <TextField {...params} label={"Search " + item.label} />}
                            />
                        ))
                    }
                    {!isLoading && <FormControl sx={{ width: "100px" }} size='small'>
                        <InputLabel>Status</InputLabel>
                        <Select defaultValue={"none"} label="Status" onChange={(e) => {
                            if (e.target.value == "none") return setFilterModel(prev => ({
                                ...prev, items: [
                                    { field: "status", operator: 'contains', value: "" }
                                ]
                            }))
                            setFilterModel(prev => ({
                                ...prev, items: [
                                    { field: "status", operator: 'contains', value: e.target.value }
                                ]
                            }))
                        }}>
                            <MenuItem value="none">None</MenuItem>
                            <MenuItem value="active">Active</MenuItem>
                            <MenuItem value="expired">Expired</MenuItem>
                        </Select>
                    </FormControl>}
                    {!isLoading && <TextField size='small' label="Global Search" onChange={(e) => {
                        setFilterModel(prev => ({ ...prev, quickFilterValues: [e.target.value] }))
                    }} />}
                    {!isLoading && <Box display={"flex"} gap={"10px"} alignItems={"center"} paddingTop={"5px"}>
                        <TextField value={convertISOToInputDate(sliderVal[0])} onChange={(e) => setSliderVal([new Date(e.target.value).getTime() / 1000, sliderVal[1]])} size='small' type='date' label={"Start Date"} InputLabelProps={{ shrink: true }} />
                        -
                        <TextField value={convertISOToInputDate(sliderVal[1])} onChange={(e) => setSliderVal([sliderVal[0], new Date(e.target.value).getTime() / 1000])} size='small' type='date' label={"End Date"} InputLabelProps={{ shrink: true }} />
                    </Box>}
                    <Button variant='contained' onClick={handleExportCSV} >Export To CSV</Button>
                    {/* <Box display={"flex"} gap={"10px"} flexDirection={"column"}  position={"relative"} zIndex={999} bgcolor={"white"}>
                        <Box display={"flex"} gap={"10px"} alignItems={"center"} paddingTop={"5px"}>
                            <TextField value={convertISOToInputDate(sliderVal[0])} onChange={(e) => setSliderVal([new Date(e.target.value).getTime() / 1000, sliderVal[1]])} size='small' type='date' label={"Start Date"} InputLabelProps={{ shrink: true }} />
                            -
                            <TextField value={convertISOToInputDate(sliderVal[1])} onChange={(e) => setSliderVal([sliderVal[0], new Date(e.target.value).getTime() / 1000])} size='small' type='date' label={"End Date"} InputLabelProps={{ shrink: true }} />
                        </Box>
                        <DateRange sx={{ position: "absolute", top:"100%" }} minMax={minMax} sliderValues={sliderVal} setSliderValues={setSliderVal} />
                    </Box> */}
                </Box>
            </Box>
            {!isLoading &&
                <DataGrid
                    checkboxSelection={false}
                    disableRowSelectionOnClick
                    // onCellKeyDown={handleKeyDown}
                    sx={{ width: "100%", maxHeight: "90vh", minHeight: "90vh" }}
                    rows={filteredData}
                    columns={columns}
                    rowsPerPageOptions={[25, 50, 100]}
                    rowSelectionModel={selectionModel}
                    onRowSelectionModelChange={(value) => setSelectionModel(value)}
                    onFilterModelChange={(model) => setFilterModel(model)}
                    filterModel={filterModel}
                />}
        </Box>
    )
}

export default Groups;



function convertISOToInputDate(isoDateString) {
    var date = new Date(isoDateString * 1000);
    var year = date.getFullYear();
    var month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-based
    var day = date.getDate().toString().padStart(2, '0');
    return `${year}-${month}-${day}`;
}