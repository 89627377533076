import { Navigate, Route, Routes } from "react-router-dom";
import Login from "../Admin/Login/Login";

import SignUp from "../Admin/Register/Register";
import Dashboard from "../Dashboard/Dashboard";
import EmployeesInfo from "../Admin/EmployeesInfo/EmployeesInfo";
import UsersInfo from "../Admin/UsersInfo/UsersInfo";
import SheetInfo from "../Admin/SheetInfo/SheetInfo";
import GroupsInfo from "../Admin/GroupsInfo/GroupsInfo";
import { useContext, useEffect, useState } from "react";
import { Context } from "../../Context";
import SheetsUsed from "../Admin/SheetsUsed/SheetsUsed";
import Reports from "../Admin/Reports/Report";
import IndividualSheetUsage from "../IndividualSheetUsage/IndividualSheetUsage";
import UserWizeIndividualSheetUsage from "../IndividualSheetUsage/UserWizeIndividualSheetUsage";
import Logs from "../Logs/Logs";
import ClientsReport from "../ClientsReport";

const AllRoutes = () => {
    const { isAdmin } = useContext(Context);
    const [loading, setLoading] = useState(true)
    useEffect(() => {
        setLoading(false)
    }, [isAdmin])
    return (
        <Routes>
            <Route path="/" element={<Navigate to={`/login`} />} />
            {isAdmin && <Route path={`/admin/employeesInfo`} element={<EmployeesInfo />} />}
            {isAdmin && <Route path={`/logs`} element={<Logs />} />}
            {!loading && <><Route path={`${isAdmin ? "/admin" : "/client"}/usersInfo`} element={<UsersInfo />} />
                <Route path={`${isAdmin ? "/admin" : "/client"}/sheetsUsed/:userID`} element={<SheetsUsed />} />
                <Route path={`${isAdmin ? "/admin" : "/client"}/reports/:userID`} element={<Reports />} />
                <Route path={`${isAdmin ? "/admin" : "/client"}/sheetInfo`} element={<SheetInfo />} />
                <Route path={`${isAdmin ? "/admin" : "/client"}/groupInfo`} element={<GroupsInfo />} />
                <Route path={`/dashboard`} element={<Dashboard />} />
                <Route path={`/dashboard/sheetUsage/:sheetID`} element={<IndividualSheetUsage />} />
                <Route path={`/dashboard/userWizesheetUsage/:sheetID`} element={<UserWizeIndividualSheetUsage />} />

            </>
            }
            <Route path={`/login`} element={<Login />} />
            <Route path={`/register`} element={<SignUp />} />
            <Route path={`/clientsReport`} element={<ClientsReport />} />
        </Routes>
    )
}

export default AllRoutes;