import Checkbox from '@mui/material/Checkbox';
import { Box, Button, FormControl, IconButton, InputLabel, MenuItem, Modal, Pagination, Select, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TextField, Tooltip, Typography } from '@mui/material'
import { Navigate, useNavigate, useSearchParams } from 'react-router-dom';
import EmployeesInfoCreate from './EmployeesInfoCreate';
import { useContext, useEffect, useState } from 'react';
import axios from '../../../HTTP';
import { Context } from '../../../Context';
import SaveIcon from '@mui/icons-material/Save';
import DeleteIcon from '@mui/icons-material/Delete';
import "./Employees.css"
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
};
const tableCell = {
  border: "1px solid gray"
}

const EmployeesInfo = () => {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [employees, setEmployees] = useState([])
  const { apiLink, selectAll, ActivateToast, PostToLogs, setSelectedRows, selectedRows, token, isAdmin } = useContext(Context)
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [page, setPage] = useState(1);
  const [isAllSelected, setIsAllSelected] = useState(false)
  const [rowsPerPage, setRowsPerPage] = useState(40);
  const [totalPages, setTotalPages] = useState(10);
  const [totalCount, setTotalCount] = useState("");
  const [searchParams, setSearchParams] = useSearchParams()
  const [keyword, setKeyword] = useState(searchParams.get("keyword") || "");
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
  };

  const handleDelete = (event) => {
    const selectedRowIDs = Object.keys(selectedRows);
    setEmployees(employees.filter(item => !selectedRowIDs.includes(item._id)));
    for (let i in selectedRows) {
      selectedRows[i].handleDelete();
    }
    setSelectedRows([])
    setIsAllSelected(false)
    setIsDeleteModalOpen(false)
  };

  const handleUpdate = (event) => {
    if (Object.entries(selectedRows).length == 0) return ActivateToast("Please Select rows to update", "error")
    for (let i in selectedRows) {
      selectedRows[i].setIsUpdateRequired(false);
      selectedRows[i].setIsSelected(false);
    }
    setSelectedRows([])
    setIsAllSelected(false)
    setSelectedRows({})
  };
  useEffect(() => {
    axios.get(`employees?limit=${rowsPerPage}&page=${page}&keyword=${keyword}`, token).then(({ data }) => {
      setEmployees(data.data);
      setTotalPages(data.totalPages || 1);
      setTotalCount(data.totalCount);
    })
    for (let i in selectedRows) {
      selectedRows[i].setIsSelected(false);
    }
    setSelectedRows([])
    setIsAllSelected(false)
    setSelectedRows({})
  }, [page, rowsPerPage, keyword])

  useEffect(() => {
    if (Object.entries(selectedRows).length == employees.length) {
      setIsAllSelected(true)
    }
    else setIsAllSelected(false)
  }, [selectedRows, employees])


  if (!token) return <Navigate to={`/login`} />
  return (
    <Box p={"30px"} display={"flex"} minHeight={"calc( 100vh - 60px )"} flexDirection={"column"} justifyContent={"space-between"}>
      <Box>
        <Box display={"flex"} flexDirection={"row-reverse"} justifyContent={"space-between"} alignItems={"center"} gap={"20px"}>
          <Button onClick={() => setIsOpen(true)} sx={{ height: "50px", width: "276px", padding: "10px 0", borderRadius: "20px", textTransform: "none", bgcolor: "#57B657", color: "white", "&:hover": { bgcolor: "#66d466", color: "white" } }}>
            Register New Employee
          </Button>
          <EmployeesInfoCreate setEmployees={setEmployees} state={[isOpen, setIsOpen]} />
        </Box>
        <br />

        <Box display={"flex"} flexDirection={["column-reverse", "row", "row", "row"]} justifyContent={"space-between"} alignItems={"center"} gap={"20px"}>

          <Box display={"flex"} alignItems={"center"} gap={"10px"}>
            <FormControl sx={{ m: 1, minWidth: 80 }}>
              <InputLabel id="demo-simple-select-autowidth-label">Rows Per Page</InputLabel>
              <Select
                labelId="demo-simple-select-autowidth-label"
                id="demo-simple-select-autowidth"
                value={rowsPerPage}
                onChange={handleChangeRowsPerPage}
                sx={{ width: "150px" }}
                label="Rows Per Page"
              >
                <MenuItem value={10}>10</MenuItem>
                <MenuItem value={40}>40</MenuItem>
                <MenuItem value={60}>60</MenuItem>
                <MenuItem value={80}>80</MenuItem>
                <MenuItem value={100}>100</MenuItem>
              </Select>
            </FormControl>
            <Tooltip title="Save">
              <Button onClick={handleUpdate} sx={{ padding: "10px 25px", borderRadius: "20px", textTransform: "none", bgcolor: "#57B657", color: "white", "&:hover": { bgcolor: "#66d466", color: "white" } }}>
                < SaveIcon />
              </Button>
            </Tooltip>
            <Tooltip title="Delete">
              <Button onClick={() => {
                if (Object.entries(selectedRows).length == 0) return ActivateToast("Please Select rows to delete", "error")
                setIsDeleteModalOpen(true)
              }} sx={{ padding: "10px 25px", borderRadius: "20px", textTransform: "none", bgcolor: "#c6483f", color: "white", "&:hover": { bgcolor: "#c95f58", color: "white" } }}>
                <DeleteIcon />
              </Button>
            </Tooltip>
          </Box>
          <Modal
            open={isDeleteModalOpen}
            onClose={() => setIsDeleteModalOpen(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <Box bgcolor={"white"} p={"20px"} borderRadius={"20px"}>
                <Typography fontWeight={"700"} color={"gray"}>
                  Do you want to delete {Object.entries(selectedRows).length} employee ?
                </Typography>
                <br />
                <Box display={"flex"} justifyContent={"space-evenly"} gap={"10px"}>
                  <Button onClick={() => setIsDeleteModalOpen(false)} sx={{ flex: 1, padding: "10px 25px", borderRadius: "10px", textTransform: "none", bgcolor: "#008bda", color: "white", "&:hover": { bgcolor: "#0096ff", color: "white" } }}>Cancel</Button>
                  <Button onClick={handleDelete} sx={{ flex: 1, padding: "10px 25px", borderRadius: "10px", textTransform: "none", bgcolor: "#c6483f", color: "white", "&:hover": { bgcolor: "#c95f58", color: "white" } }}>Delete</Button>
                </Box>
              </Box>
            </Box>
          </Modal>
          <Box display={"flex"} gap={"10px"}>
            <Button variant='contained' onClick={(e) => {
              setKeyword("");
              setSearchParams("")
            }}>Reset</Button>
            <TextField placeholder='Search' value={keyword} onChange={(e) => {
              setKeyword(e.target.value)
              setSearchParams({ keyword: e.target.value })
            }} />
          </Box>
        </Box>

        <TableContainer sx={{ width: "100%", maxHeight:"100vh"  }}>
          <Table aria-label="lead table" stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell sx={tableCell} ></TableCell>
                <TableCell sx={tableCell} >Name</TableCell>
                <TableCell sx={tableCell} >Email</TableCell>
                <TableCell sx={tableCell} >Phone No.</TableCell>
                <TableCell sx={tableCell} >Is Admin</TableCell>
                <TableCell sx={tableCell} >View</TableCell>
                <TableCell sx={tableCell} >Edit</TableCell>

                <TableCell sx={tableCell} >
                  <Checkbox checked={Object.entries(selectedRows).length == employees.length} onChange={() => {
                    setIsAllSelected(!isAllSelected);
                    for (let i of selectAll) {
                      if (!isAllSelected) {
                        i.setIsSelected(true)
                      }
                      else {
                        i.setIsSelected(false)
                      }
                    }
                  }} />
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {
                (employees || []).map((item, ind) => (
                  <TableDataRow axios={axios} key={item._id} bg={ind % 2 == 0 ? "#efefef" : "white"} data={item} apiLink={apiLink} employees={employees} setEmployees={setEmployees} />
                ))
              }
            </TableBody>
          </Table>
        </TableContainer>
        <br />
        <Box>
          Showing {page * rowsPerPage - rowsPerPage + 1} to {page * rowsPerPage - rowsPerPage + employees.length} of {totalCount}
        </Box>
      </Box>
      <br />
      <Box justifySelf={"flex-end"} display={"flex"} justifyContent={"center"}>
        <Pagination count={totalPages} page={page} onChange={handleChangePage} />
      </Box>
    </Box>
  )
}

function TableDataRow({ data, axios, employees, setEmployees, bg }) {
  const [isUpdateRequired, setIsUpdateRequired] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [permissions, setPermissions] = useState(data.permissions || {
    view: false,
    edit: false
  })
  const [isEmployeeAdmin, setIsEmployeeAdmin] = useState(!!data.isAdmin || false)
  const [isSelected, setIsSelected] = useState(false)
  const [count, setCount] = useState(1);
  const { selectAll, setSelectAll, token, setSelectedRows, PostToLogs } = useContext(Context)
  const handleUpdate = () => {
    setIsUpdateRequired(false);
    axios.patch(`employees/${data._id}`, {
      permissions: permissions,
      isAdmin: isEmployeeAdmin
    })
    PostToLogs({
      type: "Employee Update",
      activity: `Employee Permissions Changed - ${data.fullName}`
    })
  }
  const handleDelete = () => {
    let temp = employees.filter(item => item._id != data._id);
    setEmployees(temp)
    PostToLogs({
      type: "Employee Delete",
      activity: `Employee Deleted - ${data.fullName}`
    })
    axios.delete(`employees/${data._id}`)
  }
  useEffect(() => {
    setSelectAll(prev => [...prev, {
      setIsSelected, handleDelete, setIsUpdateRequired
    }])
  }, [])
  useEffect(() => {
    if (count == 1) return setCount(2);
    if (isSelected) {
      setSelectedRows(prev => {
        return {
          ...prev, [data._id]: {
            setIsSelected, handleDelete: () => {
              axios.delete(`employees/${data._id}`, token).then(res => console.log(res));
            }, setIsUpdateRequired
          }
        }
      })
    }
    else {
      setSelectedRows(prev => {
        delete prev[data._id];
        return { ...prev };
      })
    }
  }, [isSelected])

  useEffect(() => {
    if (count == 1) return setCount(2);
    if (!isUpdateRequired) {
      handleUpdate()
    }
  }, [isUpdateRequired])

  return (
    <TableRow sx={{ bgcolor: isSelected ? "#cae2ff" : bg }}>

      <TableCell sx={tableCell}>
        <Tooltip title="Save">
          <IconButton onClick={handleUpdate} disabled={!isUpdateRequired} sx={{ textTransform: "none", bgcolor: "#57B657", color: "white", "&:hover": { bgcolor: "#66d466", color: "white" } }}>
            <SaveIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title="Delete">
          <IconButton onClick={() => setIsOpen(true)} sx={{ bgcolor: "#c6483f", color: "white", "&:hover": { bgcolor: "#c95f58", color: "white" } }}>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
        <Modal
          open={isOpen}
          onClose={() => setIsOpen(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Box bgcolor={"white"} p={"20px"} borderRadius={"20px"}>
              <Typography fontWeight={"700"} color={"gray"}>
                Do you want to delete this employee ?
              </Typography>
              <br />
              <Box display={"flex"} justifyContent={"space-evenly"} gap={"10px"}>
                <Button onClick={() => setIsOpen(false)} sx={{ flex: 1, padding: "10px 25px", borderRadius: "10px", textTransform: "none", bgcolor: "#008bda", color: "white", "&:hover": { bgcolor: "#0096ff", color: "white" } }}>Cancel</Button>
                <Button onClick={handleDelete} sx={{ flex: 1, padding: "10px 25px", borderRadius: "10px", textTransform: "none", bgcolor: "#c6483f", color: "white", "&:hover": { bgcolor: "#c95f58", color: "white" } }}>Delete</Button>
              </Box>
            </Box>
          </Box>
        </Modal>
      </TableCell>
      <TableCell sx={tableCell}>{data?.fullName}</TableCell>
      <TableCell sx={tableCell}>{data?.email}</TableCell>
      <TableCell sx={tableCell}>{data?.phoneNo}</TableCell>
      <TableCell sx={tableCell}>
        <Checkbox checked={isEmployeeAdmin} onClick={() => {
          setIsEmployeeAdmin(prev => !prev)
          setIsUpdateRequired(true)
        }} />
      </TableCell>
      <TableCell sx={tableCell}>
        <Checkbox checked={permissions.view} onClick={() => {
          setPermissions({ ...permissions, view: !permissions.view })
          setIsUpdateRequired(true)
        }} />
      </TableCell>
      <TableCell sx={tableCell}>
        <Checkbox checked={permissions.edit} onClick={() => {
          setPermissions({ ...permissions, edit: !permissions.edit });
          setIsUpdateRequired(true)
        }} />
      </TableCell>
      <TableCell sx={tableCell}>
        <Checkbox checked={isSelected} onChange={() => setIsSelected(!isSelected)} />
      </TableCell>
    </TableRow>
  )
}

export default EmployeesInfo;