import { Autocomplete, Box, Button, MenuItem, Modal, Select, TextField, Typography } from '@mui/material'
import React, { useContext, useEffect, useMemo, useState } from 'react'
import { Context } from '../../../Context';
import axios from '../../../HTTP';
import { uid } from 'uid';
import DeleteIcon from '@mui/icons-material/Delete';
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: ["100vw", "80vw"],
    maxHeight: ["80vh"],
    borderRadius: "20px 0px 0px 20px",
    overflowY: "scroll"
};

function dateNow(date) {
    const tempDate = date || new Date()
    return tempDate.getFullYear() + "-" + (tempDate.getMonth() < 10 ? "0" + (tempDate.getMonth() + 1) : + tempDate.getMonth() + 1) + "-" + (tempDate.getDate() < 10 ? "0" + (tempDate.getDate()) : + tempDate.getDate())
}

const UsersInfoCreate = ({ state, setUsersInfo, groupsInfo, uniqueGroups }) => {
    const [isOpen, setIsOpen] = state;
    const { ActivateToast, PostToLogs } = useContext(Context);
    const [groups, setGroups] = useState([
        { groupName: "", startDate: dateNow(), endDate: dateNow(), id: uid(), supportExpiry: dateNow() }
    ])

    const textFields = {
        1: [
            { name: "contactName", placeholder: "Contact Name", required: true },
            { name: "email", placeholder: "Email", required: true },
            { name: "mobile", placeholder: "Mobile", required: false },
        ],
        2: [
            { name: "companyName", placeholder: "Company Name" },
            { name: "whatsAppUsers", placeholder: "WhatsApp Users" },
            { name: "whatsAppApi", placeholder: "WhatsApp Api" },
        ],
        3: [
            { name: "address", placeholder: "Address" },
        ],
        4: [
            { name: "state", placeholder: "State" },
            { name: "city", placeholder: "City" },
            { name: "pinCode", placeholder: "Pin Code" },
        ],
        5: [
            { name: "GST_NO", placeholder: "GST No." },
            { name: "domains", placeholder: "Enter Domain ( Comma Seperated )" },
        ]

    }

    const handleSubmit = (event) => {
        event.preventDefault()
        const data = new FormData(event.currentTarget);
        let postData = {
            _id: uid(24)
        }
        for (let i in textFields) {
            for (let j of textFields[i]) {
                postData[j.name] = data.get(j.name);
            }
        }
        postData.domains = postData.domains.split(",")
        postData.groups = groups;
        PostToLogs({
            type: "User Create",
            activity: `${postData.email} Client Added`
        })
        axios.post(`usersInfo`, postData)
        setUsersInfo(prev => [...prev, postData])
        ActivateToast("New User Created", "success");
        setIsOpen(false)
    }

    return (
        <Modal
            open={isOpen}
            onClose={() => setIsOpen(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box className='deleteConfirmation' component="form" onSubmit={handleSubmit} width={"100%"} sx={style}>
                <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", bgcolor: "white", padding: "30px", borderRadius: "20px 0px 0px 20px", width: "100%" }}>
                    <Box display={"flex"} width={"100%"} gap={"15px"}>
                        {
                            textFields[1].map(item => (
                                <TextField
                                    key={item.placeholder}
                                    sx={{ margin: "10px 0" }}
                                    required={item.required}
                                    fullWidth
                                    id={item.name}
                                    label={item.placeholder}
                                    name={item.name}
                                    autoComplete={item.name}
                                />
                            ))
                        }
                    </Box>

                    <Box display={"flex"} width={"100%"} gap={"15px"}>
                        {
                            textFields[2].map(item => (
                                <TextField
                                    key={item.placeholder}
                                    sx={{ margin: "10px 0" }}
                                    required={item.required}
                                    fullWidth
                                    id={item.name}
                                    label={item.placeholder}
                                    name={item.name}
                                    autoComplete={item.name}
                                />
                            ))
                        }
                    </Box>
                    <Box display={"flex"} width={"100%"} gap={"15px"}>
                        {
                            textFields[3].map(item => (
                                <TextField
                                    key={item.placeholder}
                                    sx={{ margin: "10px 0" }}
                                    required={item.required}
                                    fullWidth
                                    id={item.name}
                                    label={item.placeholder}
                                    name={item.name}
                                    autoComplete={item.name}
                                />
                            ))
                        }
                    </Box>
                    <Box display={"flex"} width={"100%"} gap={"15px"}>
                        {
                            textFields[4].map(item => (
                                <TextField
                                    key={item.placeholder}
                                    sx={{ margin: "10px 0" }}
                                    required={item.required}
                                    fullWidth
                                    id={item.name}
                                    label={item.placeholder}
                                    name={item.name}
                                    autoComplete={item.name}
                                />
                            ))
                        }
                    </Box>
                    <Box display={"flex"} width={"100%"} gap={"15px"}>
                        {
                            textFields[5].map(item => (
                                <TextField
                                    key={item.placeholder}
                                    sx={{ margin: "10px 0" }}
                                    required={item.required}
                                    fullWidth
                                    id={item.name}
                                    label={item.placeholder}
                                    name={item.name}
                                    autoComplete={item.name}
                                />
                            ))
                        }
                    </Box>

                    <Box width={"100%"} display={"flex"} flexDirection={"column"} gap={"10px"}>
                        <Button variant='contained' sx={{ width: "9%", textTransform: "none", color: "white" }} onClick={() => {
                            let temp = JSON.parse(JSON.stringify(groups));
                            setGroups([{ groupName: "", startDate: dateNow(), endDate: dateNow(), id: uid(), supportExpiry: dateNow() }, ...temp])
                        }}>Add</Button>
                        <Box display={"flex"} justifyContent={"space-between"}>
                            <Typography fontWeight={"bold"} width={"25%"}>Group Name</Typography>
                            <Typography fontWeight={"bold"} width={"20%"}>Suport Expiry date</Typography>
                            <Typography fontWeight={"bold"} width={"20%"}>Start Date</Typography>
                            <Typography fontWeight={"bold"} width={"20%"}>End Date</Typography>
                            <Typography width={"9%"} ></Typography>
                        </Box>
                        {
                            groups.map((item, index) => (
                                <GroupTable key={item.id} uniqueGroups={uniqueGroups} index={index} data={item} setGroups={setGroups} groupsInfo={groupsInfo} />
                            ))
                        }
                        <br />

                    </Box>
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}
                    >
                        Submit
                    </Button>
                </Box>


            </Box>
        </Modal >
    )
}
function addDaysToDate(date, daysToAdd) {
    const newDate = new Date(date); // Create a new Date object to avoid modifying the original date
    newDate.setDate(newDate.getDate() + daysToAdd); // Add the days to the new date
    return newDate;
}


function GroupTable({ data, index, setGroups, groupsInfo, uniqueGroups }) {
    function handleChange({ target }) {
        const { value, name } = target;
        setGroups(prev => {
            let tempGroups = [...prev];
            for (let i = 0; i < prev.length; i++) {
                if (tempGroups[i].id == data.id) {
                    tempGroups[i][name] = value;
                    return tempGroups
                }
            }
        })
    }
    function handleAutoCompleteChange({ groupName }) {
        let { validity, subscriptionValidity } = groupsInfo.find((item) => item.groupName === groupName);
        setGroups(prev => {
            let tempGroups = [...prev];
            for (let i = 0; i < tempGroups.length; i++) {
                if (tempGroups[i].id == data.id) {
                    tempGroups[i]["groupName"] = groupName;
                    tempGroups[i].endDate = dateNow(addDaysToDate(Date.now(), validity * 365));
                    tempGroups[i].supportExpiry = subscriptionValidity || 0;
                    return tempGroups
                }
            }
        })
    }
    return (
        <Box display={"flex"} justifyContent={"space-between"}>
            <Autocomplete
                sx={{ width: "25%" }}
                options={uniqueGroups} // Use complete objects as options
                getOptionLabel={(option) => option.groupName} // Display question text
                onChange={(e, newValue) => {
                    if (newValue) {
                        handleAutoCompleteChange(newValue);
                    }
                }}
                isOptionEqualToValue={(option, value) => {
                    return option.groupName == value.groupName
                }}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label="Search Group"
                        InputProps={{
                            ...params.InputProps,
                            type: "search",
                        }}
                    />
                )}
            />
            <TextField value={data.supportExpiry} type='date' sx={{ width: "20%" }} onChange={handleChange} name='supportExpiry' />
            <TextField variant='filled' value={data.startDate} type='date' sx={{ width: "20%" }} onChange={handleChange} name='startDate' />
            <TextField variant='filled' value={data.endDate} type='date' sx={{ width: "20%", bgcolor: new Date(data.endDate) < new Date() ? "#ffd3ce" : "transparent" }} onChange={handleChange} name='endDate' />
            <Button onClick={() => {
                setGroups(prevGroups => {
                    if (prevGroups.length == 1) return prevGroups;
                    let tempArr = [];
                    for (let i = 0; i < prevGroups.length; i++) {
                        if (data.id == prevGroups[i].id) continue;
                        tempArr.push(prevGroups[i])
                    }
                    return tempArr
                })
            }} sx={{ width: "9%", borderRadius: "20px", textTransform: "none", bgcolor: "red", color: "white", "&:hover": { bgcolor: "#ff3a3a", color: "white" } }}><DeleteIcon /></Button>
        </Box>
    )
}



export default UsersInfoCreate