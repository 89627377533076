import Cookies from "js-cookie";
import React, { createContext, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "./HTTP.js"
import axe from "axios"
export const Context = createContext();
function getURL(link) {
    const parsedURL = new URL(link);

    // Get the protocol and hostname to create the base URL
    const baseURL = `${parsedURL.protocol}//${parsedURL.hostname}`;

    return baseURL;
}

export default function ContextProvider({ children }) {
    // let apiLink = "http://localhost:3001/";
    // let apiLink = "https://auth.ceoitbox.com/";
    const currentURL = window.location.href;
    let apiLink = `${getURL(currentURL)}/`;
    const [token, setToken] = useState(sessionStorage.getItem('token') || "");
    const [open, setOpen] = useState(false);
    const [navWidth, setNavWidth] = useState(Cookies.get('navWidth') || "70px");
    const [selectAll, setSelectAll] = useState([]);
    const [selectedRows, setSelectedRows] = useState({});
    const [isAdmin, setIsAdmin] = useState(false);
    const [permissions, setPermissions] = useState({ view: false, edit: false });
    const [userName, setUserName] = useState("");
    const [userEmail, setUserEmail] = useState("");
    const [userID, setUserID] = useState("");
    const [loading, setLoading] = useState(false);
    const [emailToUserName, setEmailToUserName] = useState(true);
    const [idToUserInfo, setIdToUserInfo] = React.useState({});
    const [filterModel, setFilterModel] = React.useState({
        items: [],
    });
    function ActivateToast(text, type) {
        if (!type) {
            toast(text, {
                position: "top-center",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                theme: "colored",
            });
            return;
        }
        toast[type](text, {
            position: "top-center",
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            theme: "colored",
        });
    }
    async function PostToLogs({ activity, name, email, type }) {
        let temp = await axios.post("api/logs", {
            name: name || userName,
            email: email || userEmail,
            activity,
            type,
            timeStamp: new Date()
        })
        return temp
    }

    let [cloudinaryInfo, setCloudinaryInfo] = useState({
        cloud_name: "dp8oh0vlj",
        upload_preset: "ccjsypqn",
    });

    const imageToLink = async (file) => {
        let cloud_name = cloudinaryInfo.cloud_name;
        let upload_preset = cloudinaryInfo.upload_preset;
        let formData1 = new FormData();
        formData1.append("file", file);
        formData1.append("upload_preset", upload_preset);

        let link = await axe.post(
            `https://api.cloudinary.com/v1_1/${cloud_name}/image/upload`,
            formData1
        );
        link = link.data;
        return (link.secure_url);
    };
    return <Context.Provider
        value={{
            ActivateToast,
            apiLink,
            token, setToken,
            navWidth, setNavWidth,
            open, setOpen,
            selectAll, setSelectAll,
            selectedRows, setSelectedRows,
            isAdmin, setIsAdmin,
            permissions, setPermissions,
            userName, setUserName,
            userID, setUserID,
            loading, setLoading,
            emailToUserName, setEmailToUserName,
            userEmail, setUserEmail,
            PostToLogs,
            idToUserInfo, setIdToUserInfo,
            filterModel, setFilterModel,
            imageToLink
        }}>
        <ToastContainer />
        {children}
    </Context.Provider>
}