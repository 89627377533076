import { Box, Button } from "@mui/material";
import { Context } from "../../Context";
import { useContext } from "react";
import { NavLink } from "react-router-dom";
import GridViewIcon from '@mui/icons-material/GridView';
import TableChartIcon from '@mui/icons-material/TableChart';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import BorderAllIcon from '@mui/icons-material/BorderAll';
import GroupIcon from '@mui/icons-material/Group';
import LoginIcon from '@mui/icons-material/Login';
import LogoutIcon from '@mui/icons-material/Logout';
import "./Navigation.css"
import Cookies from "js-cookie";
import LocalActivityIcon from '@mui/icons-material/LocalActivity';
const navStyles = {
    active: {
        color: "white",
        background: "#4B49AC"
    },
    inActive: {
        color: "gray",
        background: "white"
    }
}

const staticData = [
    {
        name: "Employees Info", route: "/employeesInfo", icon: <TableChartIcon sx={{ height: "50%" }} />
    },
    {
        name: "Users Info", route: "/usersInfo", icon: <PersonOutlineIcon sx={{ height: "50%" }} />
    },
    {
        name: "Sheet Info", route: "/sheetInfo", icon: <BorderAllIcon sx={{ height: "50%" }} />
    },
    {
        name: "Groups Info", route: "/groupInfo", icon: <GroupIcon sx={{ height: "50%" }} />
    }
]

const Navigation = () => {
    const { isAdmin, setIsAdmin, setPermissions } = useContext(Context);
    const { navWidth, token, setToken, PostToLogs } = useContext(Context);
    const logout = () => {
        PostToLogs({
            type: "Logout",
            activity: `Logged Out.`
        })
        setToken("");
        sessionStorage.setItem("token", "");
        setIsAdmin(false)
        setPermissions({ view: false, edit: false })
    }
    return (
        <Box bgcolor={"white"} className="navigation" zIndex={998} position={"fixed"} left={"0"} top={"60px"} p={"20px 10px"} fontSize={"14px"} fontWeight={"600"} height={`calc( 100vh - 50px )`} sx={{ transition: ".3s" }} width={navWidth} boxShadow={"rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px"} display={["none", "flex"]} flexDirection={"column"} gap={"5px"}>

            {token && <NavLink className={"nav"} to={"/dashboard"} style={({ isActive }) => {
                if (isActive) return navStyles.active;
                else return navStyles.inActive;
            }}>
                <GridViewIcon sx={{ height: "50%" }} />
                <Box sx={{ display: navWidth == "200px" ? "flex" : "none" }} className="itemText">Dashboard</Box>

            </NavLink>}


            {token && staticData.map((item) => {
                if (`${isAdmin ? "/admin" : "/client"}${item.route}` === `${isAdmin ? "/admin" : "/client"}/employeesInfo` && !isAdmin) return <></>
                return (
                    <NavLink key={item.route} className={"nav"} to={`${isAdmin ? "/admin" : "/client"}${item.route}`} style={({ isActive }) => {
                        if (isActive) return navStyles.active;
                        else return navStyles.inActive;
                    }}>
                        {item.icon}
                        <Box sx={{ display: navWidth == "200px" ? "flex" : "none" }} className="itemText">{item.name}</Box>
                    </NavLink>
                )
            })}
            {!token && <NavLink className={"nav"} to={"/login"} style={({ isActive }) => {
                if (isActive) return navStyles.active;
                else return navStyles.inActive;
            }}>
                <LoginIcon sx={{ height: "50%" }} />
                <Box sx={{ display: navWidth == "200px" ? "flex" : "none" }} className="itemText">Login</Box>
            </NavLink>}
            {(token && isAdmin) && <NavLink className={"nav"} to={"/logs"} style={({ isActive }) => {
                if (isActive) return navStyles.active;
                else return navStyles.inActive;
            }}>
                <LocalActivityIcon sx={{ height: "50%" }} />
                <Box sx={{ display: navWidth == "200px" ? "flex" : "none" }} className="itemText">Logs</Box>
            </NavLink>}
            {token && <NavLink className={"nav"} to={`/clientsReport`} style={({ isActive }) => {
                if (isActive) return navStyles.active;
                else return navStyles.inActive;
            }}>
                <PersonOutlineIcon sx={{ height: "50%" }} />
                <Box sx={{ display: navWidth == "200px" ? "flex" : "none" }} className="itemText">Client Report</Box>
            </NavLink>}
            {token && <NavLink onClick={logout} className={"nav"} to={"/login"} style={navStyles.inActive}>
                <LogoutIcon sx={{ height: "50%" }} />
                <Box sx={{ display: navWidth == "200px" ? "flex" : "none" }} className="itemText">Logout</Box>
            </NavLink>}
            
        </Box>
    )
}

export default Navigation;