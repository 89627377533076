// import * as React from 'react';
// import TextField from '@mui/material/TextField';
// import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';

// const filter = createFilterOptions();

// export default function CC() {
//   const [value, setValue] = React.useState(null);

//   return (
//     <Autocomplete
//       value={value}
//       onChange={(event, newValue) => {
//         if (typeof newValue === 'string') {
//           setValue({
//             title: newValue,
//           });
//         } else if (newValue && newValue.inputValue) {
//           // Create a new value from the user input
//           setValue({
//             title: newValue.inputValue,
//           });
//         } else {
//           setValue(newValue);
//         }
//       }}
//       filterOptions={(options, params) => {
//         const filtered = filter(options, params);

//         const { inputValue } = params;
//         // Suggest the creation of a new value
//         const isExisting = options.some((option) => inputValue === option.title);
//         if (inputValue !== '' && !isExisting) {
//           filtered.push({
//             inputValue,
//             title: `Add "${inputValue}"`,
//           });
//         }

//         return filtered;
//       }}
//       selectOnFocus
//       clearOnBlur
//     //   multiple
//       handleHomeEndKeys
//       id="free-solo-with-text-demo"
//       options={top100Films}
//       getOptionLabel={(option) => {
//         // Value selected with enter, right from the input
//         if (typeof option === 'string') {
//           return option;
//         }
//         // Add "xxx" option created dynamically
//         if (option.inputValue) {
//           return option.inputValue;
//         }
//         // Regular option
//         return option.title;
//       }}
//       renderOption={(props, option) => <li {...props}>{option.title}</li>}
//       sx={{ width: 300 }}
//       freeSolo
//       renderInput={(params) => (
//         <TextField {...params} label="Free solo with text demo" />
//       )}
//     />
//   );
// }

import * as React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';

const filter = createFilterOptions();

export default function CC({ cc ,setCc }) {
    return (
        <Autocomplete
            multiple // Enable multiple selections
            value={cc}
            required
            onChange={(event, newValue) => {
                if (!Array.isArray(newValue)) {
                    return;
                }

                // Keep only unique values
                const uniqueValues = newValue.filter((v, i, a) => a.indexOf(v) === i);

                setCc(uniqueValues);
            }}
            filterOptions={(options, params) => {
                const filtered = filter(options, params);

                const { inputValue } = params;
                // Suggest the creation of a new value
                const isExisting = options.some((option) => inputValue === option.title);
                if (inputValue !== '' && !isExisting) {
                    filtered.push({
                        inputValue,
                        title: `Add "${inputValue}"`,
                    });
                }

                return filtered;
            }}
            selectOnFocus
            clearOnBlur
            handleHomeEndKeys
            id="free-solo-with-text-demo"
            options={[]}
            getOptionLabel={(option) => {
                // Value selected with enter, right from the input
                if (typeof option === 'string') {
                    return option;
                }
                // Add "xxx" option created dynamically
                if (option.inputValue) {
                    return option.inputValue;
                }
                // Regular option
                return option.title;
            }}
            renderOption={(props, option) => <li {...props}>{option.title}</li>}
            sx={{ width: "50%" }}
            freeSolo
            fullWidth
            //   size='small'
            limitTags={1}
            renderInput={(params) => (
                <TextField {...params} label="CC" />
            )}
        />
    );
}


