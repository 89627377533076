import { Box, Button, FormControl, InputLabel, MenuItem, Pagination, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'
import { Context } from '../../Context'
import { Navigate, useNavigate, useSearchParams } from 'react-router-dom'
import axios from '../../HTTP'
import CircularProgress from '@mui/material/CircularProgress';
import Papa from 'papaparse';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
const tableCell = {
    border: "1px solid gray"
}

const SortIcon = () => {
    return <svg style={{ height: "20px" }} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path d="M137.4 41.4c12.5-12.5 32.8-12.5 45.3 0l128 128c9.2 9.2 11.9 22.9 6.9 34.9s-16.6 19.8-29.6 19.8H32c-12.9 0-24.6-7.8-29.6-19.8s-2.2-25.7 6.9-34.9l128-128zm0 429.3l-128-128c-9.2-9.2-11.9-22.9-6.9-34.9s16.6-19.8 29.6-19.8H288c12.9 0 24.6 7.8 29.6 19.8s2.2 25.7-6.9 34.9l-128 128c-12.5 12.5-32.8 12.5-45.3 0z" /></svg>
}

const exportToCSV = (data, fileName) => {
    const csv = Papa.unparse(data);
    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    const url = URL.createObjectURL(blob);
    link.href = url;
    link.setAttribute('download', fileName || 'export.csv');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
};



const Dashboard = () => {
    const navigate = useNavigate();
    const { token, userName } = useContext(Context);
    const [dashboardData, setDashboardData] = useState({});
    const [apiTrackerData, setApiTrackerData] = useState([]);
    const [expireReport, setExpireReport] = useState([]);
    const [individualUserUsageData, setIndividualUserUsageData] = useState([]);
    const [filteredApiTrackerData, setFilteredApiTrackerData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [isLoading2, setIsLoading2] = useState(true);
    const [filters, setFilters] = useState(0);
    const [expiryReportFilters, setExpiryReportFilters] = useState(0);
    const [searchParams, setSearchParams] = useSearchParams()
    const [keyword, setKeyword] = useState(searchParams.get("keyword") || "");
    const [keyword2, setKeyword2] = useState(searchParams.get("keyword2") || "");

    const [status, setStatus] = useState("active");
    const [sortBy, setSortBy] = useState({
        by: "default", order: "default"
    });
    const [sortExpiryReport, setSortExpiryReport] = useState("END-ASC");
    const [page, setPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(40);
    const [totalPages, setTotalPages] = useState(10);
    const [totalCount, setTotalCount] = useState("");
    const [reportFilter, setReportFilter] = useState("CREATION")
    const { emailToUserName } = useContext(Context);
    const [sheetsData, setSheetsData] = useState({});
    const handleReportFilter = (e) => {
        if (e.target.value == "USAGE") setFilters(30)
        setReportFilter(e.target.value)
    }
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(1);
    };

    const handleChangefilters = (event) => {
        setFilters(parseInt(event.target.value, 10));
    };

    const handleChangeExpiryReportFilters = (event) => {
        setExpiryReportFilters(parseInt(event.target.value, 10));
    };

    const handleSetSortBy = (by) => {
        let tempSortBy = {
            by,
            order: ""
        }
        if (sortBy.by == by) {
            if (sortBy.order == "des") tempSortBy.order = "asc";
            else if (sortBy.order == "asc") tempSortBy.order = "des";
        }
        else if (sortBy.by != by) {
            tempSortBy.order = "asc"
        }
        setSortBy(tempSortBy)
    }

    const handleChangeSortExpiryReport = (e) => {
        setSortExpiryReport(e.target.value)
    }

    useEffect(() => {
        let temp = apiTrackerData.filter(item => {
            if ((item.sheetName || "").toLowerCase().includes(keyword.toLowerCase()) || (item.sheetID || "").toLowerCase().includes(keyword.toLowerCase())) return true;
            else return false
        })
        if (sortBy.by !== "default" && sortBy.order !== "default") {
            temp.sort((a, b) => {
                let valueA = a[sortBy.by];
                let valueB = b[sortBy.by];

                // If sorting by count, directly compare the values
                if (sortBy.by === "count") {
                    return sortBy.order === "asc" ? valueA - valueB : valueB - valueA;
                }

                // If sorting by other fields, convert to lowercase for case-insensitive comparison
                valueA = String(valueA).toLowerCase();
                valueB = String(valueB).toLowerCase();

                if (sortBy.order === "asc") {
                    return valueA.localeCompare(valueB);
                } else {
                    return valueB.localeCompare(valueA);
                }
            });
        }

        setFilteredApiTrackerData(temp);
    }, [keyword, sortBy, apiTrackerData]);


    useEffect(() => {
        if (!token) return;
        try {
            axios.get("dashboardCount", token).then(res => {
                setDashboardData(res.data);
            })
            axios.get("sheetInfo", token).then(res => {
                let obj = {};
                for (let i of res.data) {
                    obj[i.sheetID] = { name: i.description }
                }
                setSheetsData(obj);
            })
        }
        catch (er) { }

    }, [token])

    useEffect(() => {
        if (!token) return;
        setIsLoading2(true)
        try {
            axios.get(`expireReport?page=${page}&limit=${rowsPerPage}&status=${status}&keyword=${keyword2}&filter=${expiryReportFilters}&sort=${sortExpiryReport}`, token).then(res => {
                setExpireReport(res.data.data);
                setTotalPages(res.data.totalPages)
                setIsLoading2(false)
            })
        }
        catch (er) { }

    }, [token, page, rowsPerPage, keyword2, status, expiryReportFilters, sortExpiryReport])

    useEffect(() => {
        if (!token) return;
        setIsLoading(true)
        try {
            axios.post("dashboardTrackerData", {
                filters, reportFilter
            }).then(res => {
                setApiTrackerData(res.data);
                setFilteredApiTrackerData(res.data);
                setIsLoading(false)
            })
        }
        catch (er) { }

    }, [token, filters, reportFilter])

    const handleExportCSV = () => {
        exportToCSV(filteredApiTrackerData, 'SheetsUsedReport.csv');
    };

    const handleExportCSVExpiryReport = async () => {
        let { data } = await axios.get(`expireReportCSV`, token)
        exportToCSV(data, 'ExpiryReport.csv');
    };

    if (!token) return <Navigate to={"/login"} />
    return (
        <Box width={"100%"} minHeight={"80vh"} p={"30px"}>
            <Box>
                <Typography fontSize={"24px"} fontWeight={"600"} textAlign={"center"}>Welcome {userName}</Typography>
                <br />
                <br />
            </Box>
            <Box display={"flex"} gap={"10px"} width={"100%"} justifyContent={"center"} flexDirection={["column", "column", "row", "row"]}>
                {/* <Box width={["100%", "100%", "48%", "48%"]}>
                    <img draggable={false} style={{ width: "100%", borderRadius: "30px" }} src={IMG} alt='none' />
                </Box> */}
                <Box color={"white"} width={["100%", "100%", "48%", "48%"]} display={"flex"} gap={"20px"} flexDirection={"column"}>
                    <Box display={"flex"} gap={"20px"} flexDirection={["column", "row", "row", "row"]}>
                        <Box display={"flex"} flexDirection={"column"} gap={"15px"} p={"20px"} borderRadius={"20px"} width={["100%", "48%", "48%", "48%"]} bgcolor={"#7DA0FA"} minHeight={"125px"}>
                            <Typography fontSize={"13px"}>Total Registered Employees</Typography>
                            <Typography fontSize={"30px"}>  {dashboardData?.employeesCount}</Typography>

                        </Box>
                        <Box display={"flex"} flexDirection={"column"} gap={"15px"} p={"20px"} borderRadius={"20px"} width={["100%", "48%", "48%", "48%"]} bgcolor={"#4747A1"} minHeight={"125px"}>
                            <Typography fontSize={"13px"}>Total Users</Typography>
                            <Typography fontSize={"30px"}>  {dashboardData?.usersInfoCount}</Typography>
                        </Box>
                    </Box>
                    <Box display={"flex"} gap={"20px"} flexDirection={["column", "row", "row", "row"]}>
                        <Box display={"flex"} flexDirection={"column"} gap={"15px"} p={"20px"} borderRadius={"20px"} width={["100%", "48%", "48%", "48%"]} bgcolor={"#7978E9"} minHeight={"125px"}>
                            <Typography fontSize={"13px"}>Total Sheets</Typography>
                            <Typography fontSize={"30px"}>{dashboardData?.sheetCount}</Typography>

                        </Box>
                        <Box fontSize={"13px"} display={"flex"} flexDirection={"column"} gap={"15px"} p={"20px"} borderRadius={"20px"} width={["100%", "48%", "48%", "48%"]} bgcolor={"#F3797E"} minHeight={"125px"}>
                            <Typography fontSize={"13px"}> Total groups_data</Typography>
                            <Typography fontSize={"30px"}>{dashboardData?.groupsCount}</Typography>
                        </Box>
                    </Box>
                </Box>
            </Box>
            <br />
            <Box display={"flex"} justifyContent={"space-between"} gap={"10px"}>
                <Box display={"flex"} flexWrap={"wrap"}>
                    {reportFilter != "USAGE" ? <></> : <FormControl sx={{ m: 1, minWidth: 80 }}>
                        <InputLabel id="demo-simple-select-autowidth-label">Filter</InputLabel>
                        <Select
                            labelId="demo-simple-select-autowidth-label"
                            id="demo-simple-select-autowidth"
                            value={filters}
                            onChange={handleChangefilters}
                            sx={{ width: "150px" }}
                            label="Filter"
                        >
                            <MenuItem value={0}>All</MenuItem>
                            <MenuItem value={30}>Last 30 Days</MenuItem>
                        </Select>
                    </FormControl>}
                    {reportFilter != "CREATION" ? <></> : <FormControl sx={{ m: 1, minWidth: 80 }}>
                        <InputLabel id="demo-simple-select-autowidth-label">Filter</InputLabel>
                        <Select
                            labelId="demo-simple-select-autowidth-label"
                            id="demo-simple-select-autowidth"
                            value={filters}
                            onChange={handleChangefilters}
                            sx={{ width: "150px" }}
                            label="Filter"
                        >
                            <MenuItem value={0}>All</MenuItem>
                            <MenuItem value={1}>Last 1 Day</MenuItem>
                            <MenuItem value={7}>Last 7 Days</MenuItem>
                            <MenuItem value={10}>Last 10 Days</MenuItem>
                            <MenuItem value={15}>Last 15 Days</MenuItem>
                            <MenuItem value={30}>Last 30 Days</MenuItem>
                            <MenuItem value={180}>Last 6 Months</MenuItem>
                            <MenuItem value={360}>Last 1 Year</MenuItem>
                            <MenuItem value={360 * 2}>Last 2 Year</MenuItem>

                        </Select>
                    </FormControl>}
                    <FormControl sx={{ m: 1, minWidth: 80 }}>
                        <InputLabel id="demo-simple-select-autowidth-label">Report</InputLabel>
                        <Select
                            labelId="demo-simple-select-autowidth-label"
                            id="demo-simple-select-autowidth"
                            value={reportFilter}
                            onChange={handleReportFilter}
                            sx={{ width: "150px" }}
                            label="Report"
                        >
                            <MenuItem value={"CREATION"}>By Creation</MenuItem>
                            <MenuItem value={"USAGE"}>By Usage</MenuItem>
                        </Select>
                    </FormControl>
                </Box>
                <Box display={"flex"} gap={"10px"} minHeight={"50px"} alignItems={"center"} flexWrap={"wrap"}>
                    <Button sx={{ flex: 1, minHeight: "50px" }} variant='contained' onClick={handleExportCSV}>Export To CSV</Button>
                    <Button sx={{ flex: 1, minHeight: "50px" }} variant='contained' onClick={(e) => {
                        setKeyword("");
                        setSearchParams({ keyword: "", keyword2: searchParams.get("keyword2") || "" })
                    }}>Reset</Button>
                    <TextField sx={{ minHeight: "50px" }} placeholder='Search' value={keyword} onChange={(e) => {
                        setKeyword(e.target.value)
                        setSearchParams({ keyword: e.target.value, keyword2: searchParams.get("keyword2") || "" })
                    }
                    } />
                </Box>
            </Box>
            <Box minHeight={"50vh"} width={"100%"} display={"flex"} flexDirection={"column"} alignItems={"center"} gap={"20px"}>
                <Typography fontSize={"20px"} fontWeight={"bold"}>Sheets Used Report</Typography>
                {isLoading && <CircularProgress size={150} sx={{ margin: "25vh 0" }} />}
                {!isLoading && <TableContainer sx={{ width: "100%", maxHeight: "80vh" }}>
                    <Table stickyHeader aria-label="table">
                        <TableHead>
                            <TableRow>
                                <TableCell onClick={() => handleSetSortBy("sheetName")} sx={{ ...tableCell, width: "45vw", cursor: "pointer" }}>
                                    <Box display={"flex"} gap={"10px"} alignItems={"center"} justifyContent={"space-between"}>Sheet <SortIcon /></Box>
                                </TableCell>
                                <TableCell onClick={() => handleSetSortBy("sheetID")} sx={{ ...tableCell, cursor: "pointer" }}>
                                    <Box display={"flex"} gap={"10px"} alignItems={"center"} justifyContent={"space-between"}>Sheet ID <SortIcon /></Box>
                                </TableCell>
                                <TableCell onClick={() => handleSetSortBy("count")} sx={{ ...tableCell, cursor: "pointer" }}>
                                    <Box display={"flex"} gap={"10px"} alignItems={"center"} justifyContent={"space-between"}>Count <SortIcon /></Box>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                filteredApiTrackerData.map((item, index) => {
                                    return <TableRow className='tablerow' sx={{ bgcolor: index % 2 == 0 ? "#efefef" : "white" }} onClick={(e) => {
                                        if (reportFilter == "CREATION") {
                                            if (window.getSelection().toString().length > 0) {
                                                e.preventDefault();
                                            } else {
                                                navigate("/dashboard/sheetUsage/" + item.sheetID)
                                            }
                                        }
                                        else if (reportFilter == "USAGE") {
                                            if (window.getSelection().toString().length > 0) {
                                                e.preventDefault();
                                            } else {
                                                navigate("/dashboard/userWizesheetUsage/" + item.sheetID)
                                            }
                                        }
                                    }}>
                                        <TableCell sx={tableCell}>{item.sheetName}</TableCell>
                                        <TableCell sx={tableCell}>{item.sheetID}</TableCell>
                                        <TableCell sx={tableCell}>{item.count}</TableCell>
                                    </TableRow>
                                })
                            }
                        </TableBody>
                    </Table>
                </TableContainer>}
            </Box>
            <br />
            <Box minHeight={"50vh"} width={"100%"} display={"flex"} flexDirection={"column"} alignItems={"center"} gap={"20px"}>
                <Typography fontSize={"20px"} fontWeight={"bold"}>Users Groups Report</Typography>
                <Box width={"100%"} display={"flex"} flexWrap={"wrap"} flexDirection={["column-reverse", "row", "row", "row"]} justifyContent={"space-between"} alignItems={"center"} gap={"20px"}>

                    <Box display={"flex"} flexWrap={"wrap"} alignItems={"center"} gap={"10px"}>
                        <FormControl sx={{ minWidth: 80 }}>
                            <InputLabel id="demo-simple-select-autowidth-label">Rows Per Page</InputLabel>
                            <Select
                                labelId="demo-simple-select-autowidth-label"
                                id="demo-simple-select-autowidth"
                                value={rowsPerPage}
                                onChange={handleChangeRowsPerPage}
                                sx={{ width: "150px" }}
                                label="Rows Per Page"
                            >
                                <MenuItem value={10}>10</MenuItem>
                                <MenuItem value={40}>40</MenuItem>
                                <MenuItem value={60}>60</MenuItem>
                                <MenuItem value={80}>80</MenuItem>
                                <MenuItem value={100}>100</MenuItem>
                            </Select>
                        </FormControl>
                        <FormControl sx={{ minWidth: 80 }}>
                            <InputLabel id="demo-simple-select-autowidth-label">Status</InputLabel>
                            <Select
                                labelId="demo-simple-select-autowidth-label"
                                id="demo-simple-select-autowidth"
                                value={status}
                                onChange={(e) => setStatus(e.target.value)}
                                sx={{ width: "150px" }}
                                label="Status"
                            >
                                <MenuItem value={"expired"}>Expired</MenuItem>
                                <MenuItem value={"active"}>Active</MenuItem>
                            </Select>
                        </FormControl>
                        <FormControl sx={{ minWidth: 80 }}>
                            <InputLabel id="demo-simple-select-autowidth-label">Filter</InputLabel>
                            <Select
                                labelId="demo-simple-select-autowidth-label"
                                id="demo-simple-select-autowidth"
                                value={expiryReportFilters}
                                onChange={handleChangeExpiryReportFilters}

                                sx={{ width: "150px" }}
                                label="Filter"
                            >
                                <MenuItem value={0}>All</MenuItem>
                                <MenuItem value={1}>Last 1 Day</MenuItem>
                                <MenuItem value={7}>Last 7 Days</MenuItem>
                                <MenuItem value={10}>Last 10 Days</MenuItem>
                                <MenuItem value={15}>Last 15 Days</MenuItem>
                                <MenuItem value={30}>Last 30 Days</MenuItem>
                                <MenuItem value={180}>Last 6 Months</MenuItem>
                                <MenuItem value={360}>Last 1 Year</MenuItem>
                                <MenuItem value={360 * 2}>Last 2 Year</MenuItem>
                            </Select>
                        </FormControl>
                        <FormControl sx={{ minWidth: 80 }}>
                            <InputLabel id="demo-simple-select-autowidth-label">Sort</InputLabel>
                            <Select
                                labelId="demo-simple-select-autowidth-label"
                                id="demo-simple-select-autowidth"
                                value={sortExpiryReport}
                                onChange={handleChangeSortExpiryReport}
                                sx={{ width: "150px" }}
                                label="Sort"
                            >
                                <MenuItem sx={{ display: "flex", justifyContent: "space-between" }} value={"END-ASC"}>By End Date <ArrowUpwardIcon sx={{ height: "15px" }} /></MenuItem>
                                <MenuItem sx={{ display: "flex", justifyContent: "space-between" }} value={"END-DES"}>By End Date  <ArrowDownwardIcon sx={{ height: "15px" }} /></MenuItem>
                                <MenuItem sx={{ display: "flex", justifyContent: "space-between" }} value={"START-ASC"}>By Start Date  <ArrowUpwardIcon sx={{ height: "15px" }} /></MenuItem>
                                <MenuItem sx={{ display: "flex", justifyContent: "space-between" }} value={"START-DES"}>By Start Date  <ArrowDownwardIcon sx={{ height: "15px" }} /></MenuItem>
                            </Select>
                        </FormControl>
                    </Box>
                    <Box display={"flex"} flexWrap={"wrap"} gap={"10px"}>
                        <Button sx={{ height: "100%", minHeight: "50px" }} variant='contained' onClick={handleExportCSVExpiryReport}>Export To CSV</Button>
                        <Button sx={{ minHeight: "50px" }} variant='contained' onClick={(e) => {
                            setKeyword2("");
                            setSearchParams({ keyword2: "", keyword: searchParams.get("keyword") || "" })
                        }}>Reset</Button>
                        <TextField placeholder='Search' value={keyword2} onChange={(e) => {
                            setKeyword2(e.target.value);
                            setSearchParams({ keyword2: e.target.value, keyword: searchParams.get("keyword") || "" })
                        }} />
                    </Box>
                </Box>
                {isLoading2 && <CircularProgress size={150} sx={{ margin: "25vh 0" }} />}
                {!isLoading2 && <TableContainer sx={{ width: "100%", maxHeight: "80vh" }}>
                    <Table stickyHeader aria-label="table">
                        <TableHead>
                            <TableRow>
                                <TableCell sx={{ ...tableCell, cursor: "pointer" }}>
                                    <Box display={"flex"} gap={"10px"} alignItems={"center"} justifyContent={"space-between"}>Name</Box>
                                </TableCell>
                                <TableCell sx={{ ...tableCell, cursor: "pointer" }}>
                                    <Box display={"flex"} gap={"10px"} alignItems={"center"} justifyContent={"space-between"}>Email </Box>
                                </TableCell>
                                <TableCell sx={{ ...tableCell, cursor: "pointer" }}>
                                    <Box display={"flex"} gap={"10px"} alignItems={"center"} justifyContent={"space-between"}>Group Name</Box>
                                </TableCell>
                                <TableCell sx={{ ...tableCell, cursor: "pointer" }}>
                                    <Box display={"flex"} gap={"10px"} alignItems={"center"} justifyContent={"space-between"}>Status </Box>
                                </TableCell>
                                <TableCell sx={{ ...tableCell, cursor: "pointer" }}>
                                    <Box display={"flex"} gap={"10px"} alignItems={"center"} justifyContent={"space-between"}>Start Date</Box>
                                </TableCell>
                                <TableCell sx={{ ...tableCell, cursor: "pointer" }}>
                                    <Box display={"flex"} gap={"10px"} alignItems={"center"} justifyContent={"space-between"}>End Date</Box>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                expireReport.map((item, index) => {
                                    return <TableRow sx={{ bgcolor: index % 2 == 0 ? "#efefef" : "white" }}>
                                        <TableCell sx={tableCell}>{item.name}</TableCell>
                                        <TableCell sx={tableCell}>{item.email}</TableCell>
                                        <TableCell sx={tableCell}>{item.groupName}</TableCell>
                                        <TableCell sx={tableCell}>{item.status}</TableCell>
                                        <TableCell sx={tableCell}>{new Date(item.startDate).toLocaleDateString("in")}</TableCell>
                                        <TableCell sx={tableCell}>{new Date(item.endDate).toLocaleDateString("in")}</TableCell>
                                    </TableRow>
                                })
                            }
                        </TableBody>
                    </Table>
                </TableContainer>}
                <Box display={"flex"} justifyContent={"center"}>
                    <Pagination count={totalPages} page={page} onChange={handleChangePage} />
                </Box>
            </Box>
        </Box >
    )
}

export default Dashboard;

function daysToMonthsAndDays(days) {
    if (days < 0) {
        return "Invalid input";
    }

    const months = Math.floor(days / 30);
    const remainingDays = days % 30;

    let result = "";
    if (months > 0) {
        result += `${months} month${months > 1 ? 's' : ''}`;
    }

    if (remainingDays > 0) {
        result += `${months > 0 ? ' and ' : ''}${remainingDays} day${remainingDays > 1 ? 's' : ''}`;
    }

    return result || "0 days";
}
