import * as React from 'react';
import { DataGrid, GridToolbarContainer, GridToolbarColumnsButton, GridToolbarQuickFilter, GridToolbarFilterButton, GridToolbarDensitySelector } from '@mui/x-data-grid';
import { Box, Button, CircularProgress, Modal, Typography } from '@mui/material';
import axios from '../../HTTP'
import { Context } from '../../Context';
import ReactQuill from 'react-quill'; // Import the ReactQuill component and its styles
import 'react-quill/dist/quill.snow.css';
import { useParams } from 'react-router-dom';
import MailModal from './MailModal';



const UserWizeIndividualSheetUsage = ({ }) => {
    const { sheetID } = useParams()
    const [individualUserUsageData, setIndividualUserUsageData] = React.useState([]);
    const [sheetsData, setSheetsData] = React.useState({});
    // const [idToUserInfo, setIdToUserInfo] = React.useState({});
    const [isMailModalOpen, setIsMailModalOpen] = React.useState(false);
    const [rowSelectionModel, setRowSelectionModel] = React.useState([]);
    const [isLoading, setIsLoading] = React.useState(true);
    const {idToUserInfo, setIdToUserInfo} = React.useContext(Context);
    const columns = [
        { field: 'name', headerName: 'Name', flex: 2 },
        { field: 'email', headerName: 'Email', flex: 2 },
        { field: 'sheetName', headerName: 'Sheet Name', flex: 2 },
        { field: 'sheetID', headerName: 'Sheet ID', flex: 2 },
        { field: 'count', headerName: 'Count', flex: 1 },
        { field: 'lastUsed', headerName: 'Last Used', flex: 2 },
    ];

    const rows = React.useMemo(() => {
        return individualUserUsageData.map((item, index) => ({
            id: item._id,
            name: idToUserInfo[item.userID]?.name || "User not Found !",
            email: idToUserInfo[item.userID]?.email,
            count: item.count,
            sheetName: sheetsData[item.sheetID]?.name,
            sheetID: item.sheetID,
            lastUsed: new Date(item.lastUsed).toLocaleDateString("in") + ' ' + new Date(item.lastUsed).toLocaleTimeString(),
        }));
    }, [individualUserUsageData, idToUserInfo, sheetsData]);


    const handleSendMail = (row) => {
        // Open the mail modal and store the row data
        setIsMailModalOpen(true);
    };


    const CustomToolbar = React.memo(() => {
        return (
            <GridToolbarContainer sx={{ display: "flex", justifyContent: "space-between" }}>
                <Box>
                    <GridToolbarFilterButton />
                    <GridToolbarDensitySelector />
                    <GridToolbarColumnsButton />
                    <Button onClick={handleSendMail}>
                        Send Mail
                    </Button>
                </Box>
                <GridToolbarQuickFilter />
            </GridToolbarContainer>
        );
    });


    const handleMailModalClose = () => {
        // Close the mail modal
        setIsMailModalOpen(false);
    };



    React.useEffect(() => {
        axios.get("sheetInfo").then(res => {
            let obj = {};
            for (let i of res.data) {
                obj[i.sheetID] = { name: i.description }
            }
            setSheetsData(obj);
        })
        axios.get(`UserWizeIndividualUserSheetUsage?sheetID=${sheetID}`).then(res => {
            console.log(res)
            setIndividualUserUsageData((res.data));
            setIsLoading(false)
        })
        // axios.get(`idToUserInfo`).then(res => {
        //     console.log(res.data)
        //     setIdToUserInfo(res.data)
        // })
    }, [])

    // React.useEffect(() => {
    //     console.log(rowSelectionModel)
    // }, [rowSelectionModel])
    return (
        <Box sx={{ padding: "30px", display: "flex", flexDirection: "column", alignItems: "center", height:"calc( 100vh - 60px )" }}>
            {isLoading && <CircularProgress size={150} sx={{ margin: "25vh 0" }} />}
            {
                !isLoading && (
                    <DataGrid
                        checkboxSelection
                        sx={{ width: "100%", maxHeight:"100vh" }}
                        rows={rows}
                        columns={columns}
                        pageSize={10}
                        rowsPerPageOptions={[10, 25, 50, 100]}
                        pagination
                        onRowSelectionModelChange={(newRowSelectionModel) => {
                            setRowSelectionModel(newRowSelectionModel);
                        }}
                        rowSelectionModel={rowSelectionModel}
                        slots={{ toolbar: CustomToolbar }}
                        filterPanelCss={(params) => ({
                            top: '50px', // Adjust the top value as needed
                        })}
                    />
                )}
            <MailModal rows={rows} rowSelectionModel={rowSelectionModel} isMailModalOpen={isMailModalOpen} handleMailModalClose={handleMailModalClose} />
        </Box>
    );
};

export default UserWizeIndividualSheetUsage;
