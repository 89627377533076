import { Box, Button, FormControl, InputLabel, MenuItem, Modal, Select, TextField, Typography } from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'
import { Context } from '../../../Context';
import axios from '../../../HTTP';
import { uid } from 'uid';
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: ["100vw", "80vw"],
    maxHeight: ["80vh"],
    borderRadius: "20px 0px 0px 20px",
    overflowY: "scroll"
};
function dateNow() {
    const tempDate = new Date()
    return tempDate.getFullYear() + "-" + (tempDate.getMonth() < 10 ? "0" + (tempDate.getMonth() + 1) : + tempDate.getMonth() + 1) + "-" + (tempDate.getDate() < 10 ? "0" + (tempDate.getDate() + 1) : + tempDate.getDate() + 1)
}
const SheetInfoCreate = ({ state, setSheetInfo }) => {
    const [isOpen, setIsOpen] = state;
    const { ActivateToast, PostToLogs } = useContext(Context);
    const textFields = {
        1: [
            { name: "sheetID", placeholder: "Sheet ID" },
            { name: "licenceVersion", placeholder: "Licence Version", type: "select", options: ["PRO", "BASIC"] },
            { name: "licenceExpiry", placeholder: "Licence Expiry", type: "select", options: ["YES", "NO"] },
            { name: "licenceType", placeholder: "Licence Type", type: "select", options: ["DOMAIN", "PER USER"] },
        ],
        2: [
            { name: "description", placeholder: "Description" },
        ],
        3: [
            { name: "updateDate", placeholder: "Update Date", type: "date" },
            { name: "version", placeholder: "Version" },
            { name: "notes", placeholder: "Notes" },
        ],
        4: [
            { name: "sheetLink", placeholder: "Sheet Link" },
        ]
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        let postData = {
            _id: uid(24)
        }
        for (let i in textFields) {
            for (let j of textFields[i]) {
                postData[j.name] = data.get(j.name);
            }
        }
        let { data: res } = await axios.get(`sheetinfo?sheetID=${postData.sheetID}`)

        if (res[0]) return ActivateToast("Sheet with same ID already exists.", "error")
        axios.post(`sheetInfo`, postData)
        PostToLogs({
            type: "Sheet Create",
            activity: `New Sheet Added - ${postData.description}, ${postData.sheetID}`
        })
        ActivateToast("New Sheet Added", "success");
        setIsOpen(false)
    }

    return (
        <Modal
            open={isOpen}
            onClose={() => setIsOpen(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box className='deleteConfirmation' component="form" onSubmit={handleSubmit} width={"100%"} sx={style}>
                <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", bgcolor: "white", padding: "30px", borderRadius: "20px 0px 0px 20px", width: "100%" }}>
                    <Box display={"flex"} width={"100%"} gap={"15px"}>
                        {
                            textFields[1].map(item => {
                                if (item.type == "select") {
                                    return (
                                        <FormControl sx={{ width: "100%", mb: "15px" }}>
                                            <InputLabel>{item.placeholder}</InputLabel>
                                            <Select
                                                sx={{ width: "100%" }}
                                                label={item.placeholder}
                                                name={item.name} >
                                                {
                                                    item.options.map(val => (
                                                        <MenuItem value={val}>{val}</MenuItem>
                                                    ))
                                                }
                                            </Select>
                                        </FormControl>
                                    )
                                }
                                if (item.type == "date") {
                                    return (
                                        <TextField
                                            key={item.name}
                                            // sx={{ margin: "10px 0" }}
                                            required
                                            fullWidth
                                            type={item.type || "text"}
                                            id={item.name}
                                            name={item.name}
                                            autoComplete={item.name}
                                        />
                                    )
                                }
                                return (
                                    <TextField
                                        key={item.name}
                                        // sx={{ margin: "10px 0" }}
                                        multiline
                                        required
                                        fullWidth
                                        type={item.type || "text"}
                                        id={item.name}
                                        label={item.placeholder}
                                        name={item.name}
                                        autoComplete={item.name}
                                    />
                                )
                            })
                        }
                    </Box>
                    <Box display={"flex"} width={"100%"} gap={"15px"}>
                        {
                            textFields[2].map(item => {
                                if (item.type == "select") {
                                    return (
                                        <FormControl sx={{ width: "100%", mb: "15px" }}>
                                            <InputLabel>{item.placeholder}</InputLabel>
                                            <Select
                                                sx={{ width: "100%" }}
                                                label={item.placeholder}
                                                name={item.name} >
                                                {
                                                    item.options.map(val => (
                                                        <MenuItem value={val}>{val}</MenuItem>
                                                    ))
                                                }
                                            </Select>
                                        </FormControl>
                                    )
                                }
                                if (item.type == "date") {
                                    return (
                                        <TextField
                                            key={item.name}
                                            // sx={{ margin: "10px 0" }}
                                            required
                                            fullWidth
                                            type={item.type || "text"}
                                            id={item.name}
                                            name={item.name}
                                            autoComplete={item.name}
                                        />
                                    )
                                }
                                return (
                                    <TextField
                                        key={item.name}
                                        sx={{ marginBottom: "10px" }}
                                       
                                        fullWidth
                                        type={item.type || "text"}
                                        id={item.name}
                                        label={item.placeholder}
                                        name={item.name}
                                        autoComplete={item.name}
                                    />
                                )
                            })
                        }
                    </Box>
                    <Box display={"flex"} width={"100%"} gap={"15px"}>
                        {
                            textFields[3].map(item => {
                                if (item.type == "select") {
                                    return (
                                        <FormControl sx={{ width: "100%", mb: "15px" }}>
                                            <InputLabel>{item.placeholder}</InputLabel>
                                            <Select
                                                sx={{ width: "100%" }}
                                                label={item.placeholder}
                                                name={item.name} >
                                                {
                                                    item.options.map(val => (
                                                        <MenuItem value={val}>{val}</MenuItem>
                                                    ))
                                                }
                                            </Select>
                                        </FormControl>
                                    )
                                }
                                if (item.type == "date") {
                                    return (
                                        <TextField
                                            key={item.name}
                                            // sx={{ margin: "10px 0" }}
                                            required
                                            fullWidth
                                            type={item.type || "text"}
                                            id={item.name}
                                            name={item.name}
                                            autoComplete={item.name}
                                        />
                                    )
                                }
                                return (
                                    <TextField
                                        key={item.name}
                                        multiline
                                        // sx={{ margin: "10px 0" }}
                                        required
                                        fullWidth
                                        type={item.type || "text"}
                                        id={item.name}
                                        label={item.placeholder}
                                        name={item.name}
                                        autoComplete={item.name}
                                    />
                                )
                            })
                        }
                    </Box>
                    <Box display={"flex"} width={"100%"} gap={"15px"}>
                        {
                            textFields[4].map(item => {
                                if (item.type == "select") {
                                    return (
                                        <FormControl sx={{ width: "100%", mb: "15px" }}>
                                            <InputLabel>{item.placeholder}</InputLabel>
                                            <Select
                                                sx={{ width: "100%" }}
                                                label={item.placeholder}
                                                name={item.name} >
                                                {
                                                    item.options.map(val => (
                                                        <MenuItem value={val}>{val}</MenuItem>
                                                    ))
                                                }
                                            </Select>
                                        </FormControl>
                                    )
                                }
                                if (item.type == "date") {
                                    return (
                                        <TextField
                                            key={item.name}
                                            sx={{ margin: "10px 0" }}
                                            required
                                            fullWidth
                                            type={item.type || "text"}
                                            id={item.name}
                                            name={item.name}
                                            autoComplete={item.name}
                                        />
                                    )
                                }
                                return (
                                    <TextField
                                        key={item.name}
                                        sx={{ margin: "10px 0" }}
                                        required
                                        fullWidth
                                        type={item.type || "text"}
                                        id={item.name}
                                        label={item.placeholder}
                                        name={item.name}
                                        autoComplete={item.name}
                                    />
                                )
                            })
                        }
                    </Box>
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}
                    >
                        Submit
                    </Button>
                </Box>


            </Box>
        </Modal >
    )
}


export default SheetInfoCreate