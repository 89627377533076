import TopNavbar from "./Components/TopNavbar/TopNavbar";
import AllRoutes from "./Components/Routes/Routes";
import Navigation from "./Components/Navigation/Navigation";
import { Box, Button } from "@mui/material";
import { useContext, useEffect } from "react";
import { Context } from "./Context";
import NavigationMobile from "./Components/Navigation/Navigation.mobile";
import axios from "./HTTP";

function App() {
  const { navWidth, setIsAdmin, setUserID, setUserName, setUserEmail, setIdToUserInfo, setPermissions, token, setLoading, setEmailToUserName } = useContext(Context);

  useEffect(() => {
    axios.get(`idToUserInfo`).then(res => {
      setIdToUserInfo(res.data)
    })

    if (!token) return;

    axios.get(`token/users`, token).then(({ data }) => {
      setLoading(true)
      setIsAdmin(sessionStorage.getItem('loggedInAs') === "user" ? false : data.isAdmin)
      setUserName(data.fullName)
      setUserEmail(data.email)
      setPermissions(data.permissions)
      setUserID(data._id)
      setLoading(false)
    })
    axios.get(`emailToUserName`).then(res => {
      setEmailToUserName(res.data)
    })

  }, [])
  return (
    <div className="App">
      <TopNavbar />
      <Navigation />
      <NavigationMobile />
      <Box id="padding" sx={{ paddingTop: "60px", paddingLeft: [0, navWidth], transition: ".3s" }}>
        <AllRoutes />
      </Box>
    </div >
  );
}

export default App;
