import Checkbox from '@mui/material/Checkbox';
import { Box, Button, FormControl, IconButton, InputLabel, MenuItem, Modal, Pagination, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Tooltip, Typography } from '@mui/material'
import { useContext, useDeferredValue, useEffect, useState, useTransition } from 'react';
import axios from '../../../HTTP';
import { Context } from '../../../Context';
import { Navigate, useParams, useSearchParams } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
};
const tableCell = {
    border: "1px solid gray"
}

const Reports = () => {
    const params = useParams();
    const { token, apiLink, selectAll, setSelectedRows, selectedRows, ActivateToast, isAdmin } = useContext(Context)
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [sheetIDS, setSheetIDS] = useState([]);
    const [open, setOpen] = useState(false);
    const [reportsData, setReportsData] = useState([])
    const [isAllSelected, setIsAllSelected] = useState(false)
    const [page, setPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(40);
    const [totalPages, setTotalPages] = useState(10);
    const [totalCount, setTotalCount] = useState("");
    const [searchParams, setSearchParams] = useSearchParams()
    const [keyword, setKeyword] = useState(searchParams.get("keyword") || "");

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(1);
    };
    const handleDelete = (event) => {
        const selectedRowIDs = Object.keys(selectedRows);
        setReportsData(reportsData.filter(item => !selectedRowIDs.includes(item._id)));
        for (let i in selectedRows) {
            selectedRows[i].handleDelete();
        }
        setSelectedRows([])
        setIsAllSelected(false)
        setIsDeleteModalOpen(false)
    };
    useEffect(() => {
        axios.get(`reports?userID=${params.userID}&limit=${rowsPerPage}&page=${page}&keyword=${keyword}`).then(({ data }) => {
            setReportsData(data.data);
            setTotalPages(data.totalPages || 1);
            setTotalCount(data.totalItems);
        })
        for (let i in selectedRows) {
            selectedRows[i].setIsSelected(false);
        }
        setSelectedRows([])
        setIsAllSelected(false)
        setSelectedRows({})

    }, [page, rowsPerPage, keyword, apiLink, setSelectedRows])

    if (!token) return <Navigate to={`/login`} />
    return (
        <Box p={"30px"} display={"flex"} minHeight={"calc( 100vh - 60px )"} flexDirection={"column"} justifyContent={"space-between"}>

            <Box>
                <Tooltip title="Back">
                    <IconButton onClick={() => window.history.back()} sx={{ bgcolor: "#1976d2", color: "white", "&:hover": { bgcolor: "#1976a1", color: "white" } }}>
                        <ArrowBackIcon />
                    </IconButton>
                </Tooltip>
                <Box display={"flex"} mt={"10px"} flexDirection={["column-reverse", "row", "row", "row"]} justifyContent={"space-between"} alignItems={"center"} gap={"20px"}>

                    <Box display={"flex"} alignItems={"center"} gap={"10px"}>
                        <FormControl sx={{ m: 1, minWidth: 80 }}>
                            <InputLabel id="demo-simple-select-autowidth-label">Rows Per Page</InputLabel>
                            <Select
                                labelId="demo-simple-select-autowidth-label"
                                id="demo-simple-select-autowidth"
                                value={rowsPerPage}
                                onChange={handleChangeRowsPerPage}
                                sx={{ width: "150px" }}
                                label="Rows Per Page"
                            >
                                <MenuItem value={10}>10</MenuItem>
                                <MenuItem value={40}>40</MenuItem>
                                <MenuItem value={60}>60</MenuItem>
                                <MenuItem value={80}>80</MenuItem>
                                <MenuItem value={100}>100</MenuItem>
                            </Select>
                        </FormControl>
                    </Box>
                    <Modal
                        open={isDeleteModalOpen}
                        onClose={() => setIsDeleteModalOpen(false)}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                    >
                        <Box sx={style}>
                            <Box bgcolor={"white"} p={"20px"} borderRadius={"20px"}>
                                <Typography fontWeight={"700"} color={"gray"}>
                                    Do you want to delete these Groups ?
                                </Typography>
                                <br />
                                <Box display={"flex"} justifyContent={"space-evenly"} gap={"10px"}>
                                    <Button onClick={() => setIsDeleteModalOpen(false)} sx={{ flex: 1, padding: "10px 25px", borderRadius: "10px", textTransform: "none", bgcolor: "#008bda", color: "white", "&:hover": { bgcolor: "#0096ff", color: "white" } }}>Cancel</Button>
                                    <Button onClick={handleDelete} sx={{ flex: 1, padding: "10px 25px", borderRadius: "10px", textTransform: "none", bgcolor: "#c6483f", color: "white", "&:hover": { bgcolor: "#c95f58", color: "white" } }}>Delete</Button>
                                </Box>
                            </Box>
                        </Box>
                    </Modal>
                    <Box display={"flex"} gap={"10px"}>
                        <Button variant='contained' onClick={(e) => {
                            setKeyword("");
                            setSearchParams("")
                        }}>Reset</Button>
                        <TextField placeholder='Search' value={keyword} onChange={(e) => {
                            setKeyword(e.target.value)
                            setSearchParams({ keyword: e.target.value })
                        }} />
                    </Box>
                </Box>
                <TableContainer className='reportsTable' sx={{ width: "100%", maxHeight:"100vh" }}>
                    <Table aria-label="lead table" stickyHeader>
                        <TableHead>
                            <TableRow>

                                <TableCell sx={tableCell} >Sheet Name</TableCell>
                                <TableCell sx={tableCell} >Sheet ID</TableCell>
                                <TableCell sx={tableCell} >Credits Allowed</TableCell>
                                <TableCell sx={tableCell} >Credits Used</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                (reportsData || []).map((item, ind) => (
                                    <TableDataRow key={item._id} sheetIDS={sheetIDS} index={ind} bg={ind % 2 == 0 ? "#efefef" : "white"} data={item} apiLink={apiLink} reportsData={reportsData} setReportsData={setReportsData} />
                                ))
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
                <br />
                <Box>
                    Showing {page * rowsPerPage - rowsPerPage + 1} to {page * rowsPerPage - rowsPerPage + reportsData?.length} of {totalCount}
                </Box>
                <br />
            </Box>
            <Box display={"flex"} justifyContent={"center"}>
                <Pagination count={totalPages} page={page} onChange={handleChangePage} />
            </Box>
        </Box>
    )
}

function TableDataRow({ data, reportsData, setReportsData, bg }) {

    const [isOpen, setIsOpen] = useState(false);

    const [isSelected, setIsSelected] = useState(false)
    const [count, setCount] = useState(1);
    const { setSelectAll, setSelectedRows, isAdmin } = useContext(Context);

    const handleDelete = () => {
        let temp = reportsData.filter(item => item._id != data._id);
        setReportsData(temp)
        axios.delete(`reportsData/${data._id}`)
    }
    useEffect(() => {
        setSelectAll(prev => [...prev, {
            setIsSelected, handleDelete
        }])
    }, [])
    useEffect(() => {
        if (count == 1) return setCount(2);
        if (isSelected) {
            setSelectedRows(prev => {
                return {
                    ...prev, [data?.sheetID]: {
                        setIsSelected, handleDelete: () => {
                            axios.delete(`reportsData/${data._id}`)
                        }
                    }
                }
            })
        }
        else {
            setSelectedRows(prev => {
                delete prev[data?.sheetID];
                return { ...prev };
            })
        }
    }, [isSelected])

    return (
        <TableRow sx={{ bgcolor: isSelected ? "#cae2ff" : bg, padding: "5px" }}>
            <TableCell sx={tableCell}>{data?.sheetName}</TableCell>
            <TableCell sx={tableCell}>{data?.sheetID}</TableCell>
            <TableCell sx={tableCell}>{data?.creditsAllowed}</TableCell>
            <TableCell sx={tableCell}>{data?.creditsUsed}</TableCell>

        </TableRow>
    )
}
export default Reports;