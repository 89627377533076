import { Visibility, VisibilityOff } from '@mui/icons-material';
import { Box, Button, FormControl, FormControlLabel, IconButton, InputAdornment, InputLabel, Modal, OutlinedInput, TextField, Typography } from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'
import { Context } from '../../../Context';
import axios from '../../../HTTP';
import { uid } from 'uid';
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400
};
const EmployeesInfoCreate = ({ state, setEmployees }) => {
    const [isOpen, setIsOpen] = state;
    const [showPassword, setShowPassword] = React.useState(false);
    const { ActivateToast, token, PostToLogs } = useContext(Context);
    const handleSubmit = async (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        let postData = {
            fullName: data.get('fullName'),
            email: data.get('email'),
            phoneNo: data.get('phoneNo'),
            password: data.get('password'),
            confirmPassword: data.get('confirmPassword'),
        }
        let { data: res } = await axios.get(`employees?email=${postData.email}`)
        if (res[0]?._id) return ActivateToast("Employee with same email already exists.", "error")
        if (postData.password !== postData.confirmPassword) return ActivateToast("Password and Confirm Password does not match.", "error")
        postData = {
            _id: uid(24),
            fullName: postData.fullName,
            email: postData.email,
            phoneNo: postData.phoneNo || "",
            password: postData.password,
            permissions: {
                view: false,
                edit: false
            },
            isAdmin: false
        }
        axios.post(`employees`, postData)
        setEmployees(prev => [...prev, postData])
        ActivateToast("New User Registered", "success");
        setIsOpen(false)
        PostToLogs({
            type: "Employee Create",
            activity: `New Employee Added - ${postData.fullName}`
        })
        axios.post("sendEmail", {
            email: postData.email,
            sub: "CEOITBOX",
            body: `<!DOCTYPE html>
            <html>
            <head>
                <title>Welcome to Our Website!</title>
                <style>
                    body {
                        font-family: Arial, sans-serif;
                        margin: 0;
                        padding: 0;
                        background-color: #f4f4f4;
                    }
                    .container {
                        max-width: 600px;
                        margin: 0 auto;
                        padding: 20px;
                        background-color: #ffffff;
                        border-radius: 5px;
                        box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1);
                    }
                    h1 {
                        color: #333333;
                    }
                    p {
                        color: #666666;
                        line-height: 1.6;
                    }
                    ul {
                        list-style-type: none;
                        padding-left: 0;
                    }
                    li {
                        margin-bottom: 10px;
                    }
                    strong {
                        color: #333333;
                    }
                    .footer {
                        margin-top: 20px;
                        text-align: center;
                        color: #999999;
                    }
                </style>
            </head>
            <body>
                <div class="container">
                    <h1>Welcome to CEOITBOX!</h1>
                    <p>Thank you for registering with us. Here are your account details:</p>
                    <ul>
                        <li><strong>User ID:</strong> ${postData.email}</li>
                        <li><strong>Password:</strong> ${postData.password}</li>
                    </ul>
                    <p>Please keep your account information secure and do not share it with anyone.</p>
                    <p>If you have any questions or need assistance, please don't hesitate to contact us.</p>
                    <div class="footer">
                        <p>Best regards,<br>Your CEOITBOX Team</p>
                    </div>
                </div>
            </body>
            </html>
            `
        }, token)
    }
    const handleClickShowPassword = () => setShowPassword((show) => !show);

    return (
        <Modal
            open={isOpen}
            onClose={() => setIsOpen(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box className='deleteConfirmation' component="form" onSubmit={handleSubmit} width={"100%"} sx={style}>
                <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", bgcolor: "white", padding: "30px", borderRadius: "20px", width: "100%" }}>
                    <Typography fontSize={"20px"}>Register new employee's details</Typography>
                    <TextField
                        sx={{ margin: "10px 0" }}
                        required
                        fullWidth
                        id="fullName"
                        label="Full Name"
                        name="fullName"
                        autoComplete="fullName"
                    />
                    <TextField
                        sx={{ margin: "10px 0" }}
                        required
                        fullWidth
                        id="email"
                        label="Email Address"
                        name="email"
                        type='email'
                        autoComplete="email"
                    />
                    <TextField
                        sx={{ margin: "10px 0" }}
                        fullWidth
                        id="phoneNo"
                        label="Phone No"
                        name="phoneNo"
                        autoComplete="phoneNo"
                        type='number'

                    />
                    <OutlinedInput
                        sx={{ margin: "10px 0" }}
                        required
                        fullWidth
                        name="password"
                        placeholder='Password'
                        id="password"
                        autoComplete="current-password"
                        type={showPassword ? 'text' : 'password'}
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPassword}
                                    edge="end"
                                >
                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                            </InputAdornment>
                        }
                    />
                    <OutlinedInput
                        sx={{ margin: "10px 0" }}
                        required
                        fullWidth
                        name="confirmPassword"
                        placeholder='Confirm Password'
                        id="confirmPassword"
                        autoComplete="current-password"
                        type={showPassword ? 'text' : 'password'}
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPassword}
                                    // onMouseDown={handleMouseDownPassword}
                                    edge="end"
                                >
                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                            </InputAdornment>
                        }
                    />

                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}
                    >
                        Submit
                    </Button>
                </Box>


            </Box>
        </Modal>
    )
}

export default EmployeesInfoCreate