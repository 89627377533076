import { Box, Button, FormControl, Autocomplete, InputLabel, MenuItem, Modal, Select, TextField, Typography } from '@mui/material'
import React, { Suspense, lazy, useContext, useEffect, useState } from 'react'
import { Context } from '../../../Context';
import axios from '../../../HTTP';
import { uid } from 'uid';
import DeleteIcon from '@mui/icons-material/Delete';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: ["100vw", "80vw"],
    maxHeight: ["80vh"],
    borderRadius: "20px 0px 0px 20px",
    overflowY: "scroll"
};

function dateNow() {
    const tempDate = new Date()
    return tempDate.getFullYear() + "-" + (tempDate.getMonth() < 10 ? "0" + (tempDate.getMonth() + 1) : + tempDate.getMonth() + 1) + "-" + (tempDate.getDate() < 10 ? "0" + (tempDate.getDate() + 1) : + tempDate.getDate() + 1)
}
const GroupsInfoCreate = ({ state, setGroupsInfo, sheetIDS }) => {
    const [isOpen, setIsOpen] = state;
    const { ActivateToast, PostToLogs } = useContext(Context);
    const [sheets, setSheets] = useState([
        { sheetID: "", licenseVersion: "BASIC", creditsAllowed: "10", id: uid() }
    ])
    const [alias, setAlias] = useState("")
    const [filteredSheets, setFilteredSheets] = useState([])
    const [keyword, setKeyword] = useState("")

    const textFields = [
        { name: "groupName", placeholder: "Group's Name" },
    ]

    const handleSubmit = async (event) => {
        event.preventDefault()
        const data = new FormData(event.currentTarget);
        let postData = {
            _id: uid(24),
            validity: data.get("validity"),
            subscriptionValidity:data.get("subscriptionValidity"),
        }
        for (let i of textFields) {
            postData[i.name] = data.get(i.name);
        }
        let { data: res } = await axios.get(`groupsInfo?groupName=${postData.groupName}`)

        if (res[0]) return ActivateToast("Group with same name already exists.", "error")
        postData.sheets = sheets;
        postData.alias = alias;

        axios.post(`groupsInfo`, postData)
        ActivateToast("New Group Created", "success");

        PostToLogs({
            type: "Group Create",
            activity: `New Group Added - ${postData.groupName}`
        })

        setIsOpen(false)
    }

    useEffect(() => {
        if (keyword == "") setFilteredSheets([...sheets])
        setFilteredSheets(sheets.filter((item) => {
            return item.sheetID.includes(keyword) || item.licenseVersion.includes(keyword)
        }))
    }, [sheets, keyword])

    return (
        <Modal
            open={isOpen}
            onClose={() => setIsOpen(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box className='deleteConfirmation' component="form" onSubmit={handleSubmit} width={"100%"} sx={style}>
                <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", bgcolor: "white", padding: "30px", borderRadius: "20px 0px 0px 20px", width: "100%" }}>
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ mt: 1, mb: 2 }}
                    >
                        Submit
                    </Button>
                    <Box display={"flex"} width={"100%"} gap={"15px"}>
                        {
                            textFields.map(item => (
                                <TextField
                                    key={item.placeholder}
                                    // sx={{ margin: "10px 0" }}
                                    required
                                    fullWidth
                                    id={item.name}
                                    label={item.placeholder}
                                    name={item.name}
                                    autoComplete={item.name}
                                />
                            ))
                        }
                        <FormControl fullWidth>
                            <InputLabel>Subscription Validity</InputLabel>
                            <Select
                                // sx={{ width: "100%" }}
                                label={"Subscription Validity"}
                                name='subscriptionValidity'
                                // onChange={handleChange}
                            >
                                <MenuItem value={"0.083"}>1 Months</MenuItem>
                                <MenuItem value={"0.5"}>6 Months</MenuItem>
                                <MenuItem value={"1"}>1 Year</MenuItem>
                                <MenuItem value={"2"}>2 Years</MenuItem>
                                <MenuItem value={"5"}>5 Years</MenuItem>
                                <MenuItem value={"10"}>10 Years</MenuItem>
                                <MenuItem value={"20"}>20 Years</MenuItem>
                                <MenuItem value={"30"}>30 Years</MenuItem>
                                <MenuItem value={"40"}>40 Years</MenuItem>
                            </Select>
                        </FormControl>
                        <FormControl fullWidth sx={{ mb: "10px" }}>
                            <InputLabel>Validity</InputLabel>
                            <Select
                                sx={{ width: "100%" }}
                                label={"Validity"}
                                name="validity"
                            >
                                <MenuItem value={"0.083"}>1 Months</MenuItem>
                                <MenuItem value={"0.5"}>6 Months</MenuItem>
                                <MenuItem value={"1"}>1 Year</MenuItem>
                                <MenuItem value={"2"}>2 Years</MenuItem>
                                <MenuItem value={"5"}>5 Years</MenuItem>
                                <MenuItem value={"10"}>10 Years</MenuItem>
                                <MenuItem value={"20"}>20 Years</MenuItem>
                                <MenuItem value={"30"}>30 Years</MenuItem>
                                <MenuItem value={"40"}>40 Years</MenuItem>
                            </Select>
                        </FormControl>
                        <TextField
                            value={alias}
                            onChange={(e) => setAlias(e.target.value)}
                            required
                            fullWidth
                            label={"Alias"}
                        />
                    </Box>
                    <br />
                    <Box width={"100%"} display={"flex"} flexDirection={"column"} gap={"10px"}>
                        <Box display={"flex"} justifyContent={"space-between"}>
                            <Button variant='contained' sx={{ width: "9%", textTransform: "none", color: "white" }} onClick={() => {
                                let temp = JSON.parse(JSON.stringify(sheets));
                                setSheets([{ sheetID: "", licenseVersion: "BASIC", creditsAllowed: "10", id: uid() }, ...temp])
                            }}>Add</Button>
                            <TextField label={"Search"} onChange={(e) => setKeyword(e.target.value)} />
                        </Box>
                        <Box display={"flex"} justifyContent={"space-between"}>
                            <Typography width={"45%"}>Sheet ID</Typography>
                            <Typography width={"20%"}>License Version</Typography>
                            <Typography width={"20%"}>Credits Allowed</Typography>
                            <Typography width={"9%"}></Typography>
                        </Box>
                        {
                            (keyword == "" ? sheets : filteredSheets).map((item, index) => (
                                <GroupTable key={item.id} index={index} data={item} setSheets={setSheets} sheetIDS={sheetIDS} />
                            ))
                        }
                        <br />
                    </Box>
                </Box>
            </Box>
        </Modal >
    )
}

function GroupTable({ data, index, setSheets, sheetIDS, view }) {
    function handleChange({ target }) {
        const { value, name } = target;
        setSheets(prev => {
            let tempGroups = [...prev];
            for (let i = 0; i < tempGroups.length; i++) {
                if (tempGroups[i].id == data.id) {
                    tempGroups[i][name] = value;
                    return tempGroups
                }
            }
        })
    }
    function handleAutoCompleteChange({ sheetID }) {
        setSheets(prev => {
            let tempGroups = [...prev];
            for (let i = 0; i < tempGroups.length; i++) {
                if (tempGroups[i].id == data.id) {
                    tempGroups[i].sheetID = sheetID;
                    return tempGroups
                }
            }
        })
    }
    return (
        <Box display={"flex"} justifyContent={"space-between"}>
            <Autocomplete
                sx={{ width: "45%" }}
                options={sheetIDS} // Use complete objects as options
                // getOptionLabel={(option) => option.sheetID} // Display question text
                getOptionLabel={(option) => {
                    let temp = sheetIDS.find(it => it.sheetID == option.sheetID);
                    return option.sheetID ? `${option.sheetID} - ${temp?.description || "Sheet does not exist in DB."}` : ""
                }}
                onChange={(e, newValue) => {
                    if (newValue) {
                        handleAutoCompleteChange(newValue);
                    }
                }}
                isOptionEqualToValue={(option, value) => {
                    return option.sheetID == value.sheetID
                }}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label="Search Sheet"
                        InputProps={{
                            ...params.InputProps,
                            type: "search",
                        }}
                    />
                )}
            />
            <Select disabled={view} sx={{ width: "20%" }} value={data.licenseVersion} onChange={handleChange} name='licenseVersion' >
                <MenuItem value={"BASIC"}>BASIC</MenuItem>
                <MenuItem value={"PRO"}>PRO</MenuItem>
            </Select>
            <TextField disabled={view} value={data.creditsAllowed} sx={{ width: "20%" }} type='number' onChange={handleChange} name='creditsAllowed' />
            {!view && <Button onClick={() => {
                setSheets(prevGroups => {
                    if (prevGroups.length == 1) return prevGroups;
                    let tempArr = [];
                    for (let i = 0; i < prevGroups.length; i++) {
                        if (index == i) continue;
                        tempArr.push(prevGroups[i])
                    }
                    console.log(tempArr)
                    return tempArr
                })
            }} sx={{ width: "9%", borderRadius: "20px", textTransform: "none", bgcolor: "red", color: "white", "&:hover": { bgcolor: "#ff3a3a", color: "white" } }}><DeleteIcon /></Button>}
        </Box>
    )
}


export default GroupsInfoCreate