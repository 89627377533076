import { Box, Button, Modal, TextField, Typography } from '@mui/material'
import axios from '../../HTTP';
import React, { useContext, useEffect, useState } from 'react'
import ReactQuill from 'react-quill'
import { Context } from "../../Context"
import CC from './freeSoloAutoComplete';
import TextEditor from './TextEditor';
import TextEditor2 from './newJoditEditor';

function MailModal({ rows, rowSelectionModel, isMailModalOpen, handleMailModalClose }) {
    const [mailContent, setMailContent] = useState("")
    const [subject, setSubject] = useState("")
    const [senderName, setSenderName] = useState("")
    const [cc, setCc] = useState([])
    const { userName, PostToLogs } = useContext(Context);
    const { ActivateToast } = useContext(Context)
    const handleSendButton = () => {
        if( mailContent.length == 0 || subject.length == 0 || senderName.length == 0) {
            return ActivateToast("Please Fill the required fields")
        }
        // Log the mail content to the console (you can replace this with your actual mail sending logic)
        console.log('Sending mail with content:', mailContent);
        const selectedRowEmails = rows.filter(item => {
            return rowSelectionModel.includes(item.id) && item.email
        }).map(item => item.email);
        
        axios.post(`sendBulkEmail`, {
            emails: selectedRowEmails,
            sub: subject || "Sheet Update",
            body: mailContent,
            senderName,
            userName,
            CC: cc.map(item => item.inputValue)
        })
        PostToLogs({
            type: "Mail Sent",
            activity: `Sent Sheet Update email to ${selectedRowEmails.length} users.`
        })
        handleMailModalClose();
    };


    return (
        <Modal disableEnforceFocus open={isMailModalOpen} onClose={handleMailModalClose}>
            <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: "70vw", bgcolor: 'white', boxShadow: 24, p: 4, borderRadius: "30px" }}>
                <Box sx={{ overflowY: "scroll", height: "600px" }}>


                    <Typography variant="h6" component="h2" sx={{ marginBottom: 2 }}>
                        Write your email
                    </Typography>
                    <Box display={"flex"} gap={"10px"}>
                        <TextField required label="Subject" onChange={(e) => setSubject(e.target.value)} sx={{ width: "50%" }} />
                        <TextField required label="Sender Name" onChange={(e) => setSenderName(e.target.value)} sx={{ width: "30%" }} />
                        <CC cc={cc} setCc={setCc} />
                    </Box>
                    <br />
                    {/* <TextEditor config={editorConfig} content={mailContent} setContent={setMailContent} /> */}
                    <TextEditor2 data={mailContent} setData={setMailContent} />
                    {/* <ReactQuill theme="snow" value={mailContent} onChange={setMailContent} /> */}
                    <Button onClick={() => handleSendButton()} variant="contained" color="primary" sx={{ marginTop: 2 }}>
                        Send
                    </Button>
                </Box>
            </Box>
        </Modal>
    )
}

export default MailModal