import React, { useState, useMemo } from 'react';
import JoditEditor from "jodit-react";

const MyEditor = ({ content, setContent, config }) => {
    const handleChange = (newContent) => {
        setContent(newContent);
    };

    const memoizedEditor = useMemo(() => (
        <JoditEditor
            value={content}
            onChange={handleChange}
            config={{
                config
            }}
        />
    ), []); // Only re-render if content changes

    return (
        <div>
            {memoizedEditor}
        </div>
    );
};

export default MyEditor;
