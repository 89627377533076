import { Autocomplete, Box, Button, MenuItem, Modal, Select, TextField, Typography } from '@mui/material';

import { DataGrid } from "@mui/x-data-grid"
import React, { useContext, useEffect, useState } from 'react'
import { Context } from '../../../Context';
import axios from '../../../HTTP';
import DeleteIcon from '@mui/icons-material/Delete';
import { uid } from 'uid';
import { useNavigate, useSearchParams } from 'react-router-dom';
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: ["100vw", "80vw"],
    maxHeight: ["80vh"],
    borderRadius: "20px 0px 0px 20px",
    overflowY: "scroll"
};
function dateNow(date) {
    const tempDate = date || new Date()
    return tempDate.getFullYear() + "-" + (tempDate.getMonth() < 10 ? "0" + (tempDate.getMonth() + 1) : + tempDate.getMonth() + 1) + "-" + (tempDate.getDate() < 10 ? "0" + (tempDate.getDate()) : + tempDate.getDate())
}
const UsersInfoUpdate = ({ state, setUsersInfo, data, _id, groupsInfo, view, uniqueGroups }) => {
    const [params] = useSearchParams()
    const [isOpen, setIsOpen] = state;
    const { ActivateToast, PostToLogs } = useContext(Context);
    const [groups, setGroups] = useState(data.groups.map(item => {
        item.id = uid();
        return { ...item }
    }) || [
            { groupName: "", startDate: dateNow(), endDate: dateNow(), id: uid(), supportExpiry: dateNow() }
        ])
    const textFields = {
        1: [
            { name: "contactName", placeholder: "Contact Name", defaultValue: data.contactName, required: true },
            { name: "email", placeholder: "Email", defaultValue: data.email, required: true },
            { name: "mobile", placeholder: "Mobile", defaultValue: data.mobile, required: false },
        ],
        2: [
            { name: "companyName", placeholder: "Company Name", defaultValue: data.companyName },
            { name: "whatsAppUsers", placeholder: "WhatsApp Users", defaultValue: data.whatsAppUsers },
            { name: "whatsAppApi", placeholder: "WhatsApp Api", defaultValue: data.whatsAppApi },
        ],
        3: [
            { name: "address", placeholder: "Address", defaultValue: data.address },
        ],
        4: [
            { name: "state", placeholder: "State", defaultValue: data.state },
            { name: "city", placeholder: "City", defaultValue: data.city },
            { name: "pinCode", placeholder: "Pin Code", defaultValue: data.pinCode },
        ],
        5: [
            { name: "GST_NO", placeholder: "GST No.", defaultValue: data.GST_NO },
            { name: "domains", placeholder: "Enter Domain ( Comma Seperated )", defaultValue: data.domains },
        ]

    }
    const nav = useNavigate();
    const handleSubmit = (event) => {
        event.preventDefault()
        const formData = new FormData(event.currentTarget);
        let postData = { _id }
        for (let i in textFields) {
            for (let j of textFields[i]) {
                postData[j.name] = formData.get(j.name);
            }
        }
        postData.domains = postData.domains.split(",")
        postData.groups = groups;
        axios.patch(`usersInfo/${_id}`, postData)
        setUsersInfo(prev => {
            let temp = [...prev];
            for (let i = 0; i < temp.length; i++) {
                if (temp[i]._id == _id) {
                    temp[i] = postData;
                    break
                }
            }
            return temp;
        })

        ActivateToast("User Updated", "success");
        let logs = generateLogs(data, postData, postData.email);
        if (logs.length > 0) {
            PostToLogs({
                type: "User Update",
                activity: `${logs}`
            })
        }
        setIsOpen(false)
        if (params.get("editModal")) {
            nav("/clientsReport");
        }
    }

    const RenderNormalText = (params) => (
        <Box sx={{ whiteSpace: 'normal' }}>{params.value}</Box>
    );

    const [usersReport, setUsersReport] = useState([]);

    const sheetsUsedColumns = [
        { field: 'sheetName', headerName: 'Tool Name', flex: 200, minWidth: 300, sortable: true, renderCell: RenderNormalText},
        { field: 'creditsAllowed', headerName: 'Credits Allowed', flex: 100, minWidth: 100, sortable: true, renderCell: RenderNormalText},
        { field: 'creditsUsed', headerName: 'Credits Used', flex: 100, minWidth: 100, sortable: true, renderCell: RenderNormalText },
    ];
    useEffect(() => {
        axios.get(`reports/${_id}`).then(res => {
            setUsersReport(res.data)
        })
    }, [])

    return (
        <Modal
            open={isOpen}
            onClose={() => setIsOpen(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box className='deleteConfirmation' component="form" onSubmit={handleSubmit} width={"100%"} sx={style}>
                <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", bgcolor: "white", padding: "30px", borderRadius: "20px 0px 0px 20px", width: "100%" }}>

                    <Box display={"flex"} width={"100%"} gap={"15px"}>
                        {
                            textFields[1].map(item => (
                                <TextField
                                    key={item.placeholder}
                                    defaultValue={item.defaultValue}
                                    sx={{ margin: "10px 0" }}
                                    required={!!item.required}
                                    fullWidth
                                    id={item.name}
                                    label={item.placeholder}
                                    name={item.name}
                                    autoComplete={item.name}
                                    disabled={view}
                                />
                            ))
                        }
                    </Box>

                    <Box display={"flex"} width={"100%"} gap={"15px"}>
                        {
                            textFields[2].map(item => (
                                <TextField
                                    defaultValue={item.defaultValue}
                                    key={item.placeholder}
                                    sx={{ margin: "10px 0" }}
                                    required={!!item.required}
                                    fullWidth
                                    id={item.name}
                                    label={item.placeholder}
                                    name={item.name}
                                    autoComplete={item.name}
                                    disabled={view}
                                />
                            ))
                        }
                    </Box>
                    <Box display={"flex"} width={"100%"} gap={"15px"}>
                        {
                            textFields[3].map(item => (
                                <TextField
                                    defaultValue={item.defaultValue}
                                    key={item.placeholder}
                                    sx={{ margin: "10px 0" }}
                                    required={!!item.required}
                                    fullWidth
                                    id={item.name}
                                    label={item.placeholder}
                                    name={item.name}
                                    autoComplete={item.name}
                                    disabled={view}
                                />
                            ))
                        }
                    </Box>
                    <Box display={"flex"} width={"100%"} gap={"15px"}>
                        {
                            textFields[4].map(item => (
                                <TextField
                                    defaultValue={item.defaultValue}
                                    key={item.placeholder}
                                    sx={{ margin: "10px 0" }}
                                    required={!!item.required}
                                    fullWidth
                                    id={item.name}
                                    label={item.placeholder}
                                    name={item.name}
                                    autoComplete={item.name}
                                    disabled={view}
                                />
                            ))
                        }
                    </Box>
                    <Box display={"flex"} width={"100%"} gap={"15px"}>
                        {
                            textFields[5].map(item => (
                                <TextField
                                    defaultValue={item.defaultValue}
                                    key={item.placeholder}
                                    sx={{ margin: "10px 0" }}
                                    required={!!item.required}
                                    fullWidth
                                    id={item.name}
                                    label={item.placeholder}
                                    name={item.name}
                                    autoComplete={item.name}
                                    disabled={view}
                                />
                            ))
                        }
                    </Box>
                    <Box width={"100%"} display={"flex"} flexDirection={"column"} gap={"10px"}>
                        {!view && <Button variant='contained' sx={{ width: "9%", textTransform: "none", color: "white" }} onClick={() => {
                            let temp = JSON.parse(JSON.stringify(groups));
                            setGroups([{ groupName: "", startDate: dateNow(), endDate: dateNow(), id: uid(), supportExpiry: dateNow() }, ...temp])
                        }}>Add</Button>}
                        <Box display={"flex"} justifyContent={"space-between"}>
                            <Typography fontWeight={"bold"} width={"25%"}>Group Name</Typography>
                            <Typography fontWeight={"bold"} width={"20%"}>Suport Expiry date</Typography>
                            <Typography fontWeight={"bold"} width={"20%"}>Start Date</Typography>
                            <Typography fontWeight={"bold"} width={"20%"}>End Date</Typography>
                            {!view && <Typography width={"9%"} ></Typography>}
                        </Box>
                        {
                            groups.map((item, index) => (
                                <GroupTable key={item.id} uniqueGroups={uniqueGroups} view={view} index={index} data={item} startDate={item.startDate} setGroups={setGroups} groupsInfo={groupsInfo} />
                            ))
                        }
                        <br />

                    </Box>
                    {!view && <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}
                    >
                        Submit
                    </Button>}

                    <Typography textAlign={"center"} fontSize={"28px"}>
                        Tool Usage Report
                    </Typography>
                    <DataGrid
                        // onCellKeyDown={handleKeyDown}
                        sx={{ width:"100%", maxHeight: "90vh" }}
                        rows={usersReport}
                        columns={sheetsUsedColumns}
                        pageSize={10}
                        getRowId={(param) => param.sheetID}
                        rowsPerPageOptions={[10, 25, 50, 100]}
                        pagination
                        disableSelectionOnClick
                        disableRowSelectionOnClick
                    />
                </Box>
            </Box>
        </Modal >
    )
}

function addDaysToDate(date, daysToAdd) {
    const newDate = new Date(date); // Create a new Date object to avoid modifying the original date
    newDate.setDate(newDate.getDate() + daysToAdd); // Add the days to the new date
    return newDate;
}


function GroupTable({ data, index, setGroups, startDate, groupsInfo, view, uniqueGroups }) {
    function handleChange({ target }) {
        const { value, name } = target;
        setGroups(prev => {
            let tempGroups = [...prev];
            for (let i = 0; i < prev.length; i++) {
                if (tempGroups[i].id == data.id) {
                    tempGroups[i][name] = value;
                    return tempGroups
                }
            }
        })
    }
    function handleAutoCompleteChange({ groupName }) {
        let { validity, subscriptionValidity } = groupsInfo.find((item) => item.groupName === groupName);
        setGroups(prev => {
            let tempGroups = [...prev];
            for (let i = 0; i < tempGroups.length; i++) {
                if (tempGroups[i].id == data.id) {
                    tempGroups[i]["groupName"] = groupName;
                    tempGroups[i].endDate = dateNow(addDaysToDate(Date.now(), validity * 365));
                    tempGroups[i].supportExpiry = subscriptionValidity || 0;
                    return tempGroups
                }
            }
        })
    }
    return (
        <Box display={"flex"} justifyContent={"space-between"}>
            <Autocomplete
                sx={{ width: "25%" }}
                options={uniqueGroups} // Use complete objects as options
                getOptionLabel={(option) => option.groupName} // Display question text
                onChange={(e, newValue) => {
                    if (newValue) {
                        handleAutoCompleteChange(newValue);
                    }
                }}
                isOptionEqualToValue={(option, value) => {
                    return option.groupName == value.groupName
                }}
                defaultValue={data}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label="Search Group"
                        InputProps={{
                            ...params.InputProps,
                            type: "search",
                        }}
                    />
                )}
            />
            <TextField value={data.supportExpiry} type='date' sx={{ width: "20%" }} onChange={handleChange} name='supportExpiry' />
            <TextField variant='filled' value={data.startDate} type='date' sx={{ width: "20%" }} onChange={handleChange} name='startDate' />
            <TextField variant='filled' disabled={view} value={data.endDate} type='date' sx={{ width: "20%", bgcolor: new Date(data.endDate) < new Date() ? "#ffd3ce" : "transparent" }} onChange={handleChange} name='endDate' />
            {!view && <Button onClick={() => {
                setGroups(prevGroups => {
                    if (prevGroups.length == 1) return prevGroups;
                    let tempArr = [];
                    for (let i = 0; i < prevGroups.length; i++) {
                        if (index == i) continue;
                        tempArr.push(prevGroups[i])
                    }
                    return tempArr
                })
            }} sx={{ width: "9%", borderRadius: "20px", textTransform: "none", bgcolor: "red", color: "white", "&:hover": { bgcolor: "#ff3a3a", color: "white" } }}><DeleteIcon /></Button>}
        </Box>
    )
}



export default UsersInfoUpdate;


// function generateLogs(oldData, newData) {
//     const logs = [];

//     // Compare groups
//     oldData.groups.forEach(oldGroup => {
//         const newGroup = newData.groups.find(group => group.groupName === oldGroup.groupName);
//         if (!newGroup) {
//             logs.push(`${oldGroup.groupName} group removed`);
//         } else if (newGroup.startDate !== oldGroup.startDate || newGroup.endDate !== oldGroup.endDate) {
//             logs.push(`${oldGroup.groupName} group endDate/startDate changed`);
//         }
//     });

//     newData.groups.forEach(newGroup => {
//         const oldGroup = oldData.groups.find(group => group.groupName === newGroup.groupName);
//         if (!oldGroup) {
//             logs.push(`${newGroup.groupName} group added`);
//         }
//     });

//     // Compare domains
//     const oldDomains = Array.isArray(oldData.domains) ? oldData.domains : oldData.domains.split(',').map(domain => domain.trim());
//     const newDomains = Array.isArray(newData.domains) ? newData.domains : newData.domains.split(',').map(domain => domain.trim());
//     const domainChanges = diffArrays(oldDomains, newDomains);
//     domainChanges.forEach(change => {
//         logs.push(`${change.type === 'added' ? 'Domain' : 'Domain removed'}: ${change.value}`);
//     });

//     // Compare other fields
//     Object.keys(oldData).forEach(key => {
//         if (['groups', '__v', 'tempOTP', 'domains'].includes(key)) return; // Skip groups and domains, already handled
//         if (oldData[key] !== newData[key]) {
//             logs.push(`${key} changed`);
//         }
//     });

//     return logs;
// }

function diffArrays(oldArray, newArray) {
    const added = newArray.filter(item => !oldArray.includes(item)).map(value => ({ type: 'added', value }));
    const removed = oldArray.filter(item => !newArray.includes(item)).map(value => ({ type: 'removed', value }));
    return [...added, ...removed];
}
function generateLogs(oldData, newData, userEmail) {
    const logs = [];
    let userLogAdded = false; // Flag to check if user log has been added

    // Compare groups
    oldData.groups.forEach(oldGroup => {
        const newGroup = newData.groups.find(group => group.groupName === oldGroup.groupName);
        if (!newGroup) {
            logs.push(`The ${oldGroup.groupName} group has been removed`);
        } else if (newGroup.startDate !== oldGroup.startDate || newGroup.endDate !== oldGroup.endDate) {
            logs.push(`The endDate or startDate has been changed for the ${oldGroup.groupName} group`);
        }
    });

    newData.groups.forEach(newGroup => {
        const oldGroup = oldData.groups.find(group => group.groupName === newGroup.groupName);
        if (!oldGroup) {
            logs.push(`A new ${newGroup.groupName} group has been added`);
        }
    });

    // Compare domains
    const oldDomains = Array.isArray(oldData.domains) ? oldData.domains : oldData.domains.split(',').map(domain => domain.trim());
    const newDomains = Array.isArray(newData.domains) ? newData.domains : newData.domains.split(',').map(domain => domain.trim());
    const domainChanges = diffArrays(oldDomains, newDomains);
    domainChanges.forEach(change => {
        logs.push(`${change.type === 'added' ? 'Domain' : 'Domain removed'}: ${change.value}`);
    });

    // Compare other fields
    Object.keys(oldData).forEach(key => {
        if (['groups', '__v', 'tempOTP', 'domains'].includes(key)) return; // Skip groups and domains, already handled
        if (oldData[key] !== newData[key]) {
            logs.push(`The ${key} has been changed from "${oldData[key]}" to "${newData[key]}"`);
        }
    });

    // Add user log if not added already
    if (!userLogAdded) {
        logs.push(`for the user with email ${userEmail}`);
        userLogAdded = true;
    }

    // Concatenate logs into a sentence
    const logsSentence = logs.join('; ');
    return logsSentence.charAt(0).toUpperCase() + logsSentence.slice(1) + '.';
}
