import { Box, Button, FormControl, InputLabel, MenuItem, Modal, Select, TextField, Typography } from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'
import { Context } from '../../../Context';
import axios from '../../../HTTP';
import { uid } from 'uid';
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: ["100vw", "80vw"],
    maxHeight: ["80vh"],
    borderRadius: "20px 0px 0px 20px",
    overflowY: "scroll"
};
function dateNow() {
    const tempDate = new Date()
    return tempDate.getFullYear() + "-" + (tempDate.getMonth() < 10 ? "0" + (tempDate.getMonth() + 1) : + tempDate.getMonth() + 1) + "-" + (tempDate.getDate() < 10 ? "0" + (tempDate.getDate() + 1) : + tempDate.getDate() + 1)
}
const SheetInfoUpdate = ({ state, setSheetInfo, data, view }) => {
    const [isOpen, setIsOpen] = state;
    const { ActivateToast, PostToLogs } = useContext(Context);

    const textFields = {
        1: [
            { name: "sheetID", placeholder: "Sheet ID", value: data.sheetID },
            { name: "licenceVersion", placeholder: "Licence Version", type: "select", options: ["PRO", "BASIC"], value: data.licenceVersion },
            { name: "licenceExpiry", placeholder: "Licence Expiry", type: "select", options: ["YES", "NO"], value: data.licenceExpiry },
            { name: "licenceType", placeholder: "Licence Type", type: "select", options: ["DOMAIN", "PER USER"], value: data.licenceType },
        ],
        2: [
            { name: "description", placeholder: "Description", value: data.description },
        ],
        3: [
            { name: "updateDate", placeholder: "Update Date", type: "date", value: data.updateDate },
            { name: "version", placeholder: "Version", value: data.version },
            { name: "notes", placeholder: "Notes", value: data.notes },
        ],
        4: [
            { name: "sheetLink", placeholder: "Sheet Link", value: data.sheetLink },
        ]
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        const formData = new FormData(event.currentTarget);
        let postData = { _id: data._id }
        for (let i in textFields) {
            for (let j of textFields[i]) {
                postData[j.name] = formData.get(j.name);
            }
        }
        axios.patch(`sheetInfo/${data._id}`, postData);

        setSheetInfo(prev => {
            let temp = [...prev];
            for (let i = 0; i < temp.length; i++) {
                if (temp[i]._id === data._id) {
                    temp[i] = postData;
                    break
                }
            }
            return temp;
        })
        ActivateToast("Sheet Updated", "success");
        let logs = generateLogs(data, postData);
        PostToLogs({
            type: "Sheet Update",
            activity: `${logs.join(", ")}, Sheet Updated - ${postData.description}, ${postData.sheetID}`
        })




        setIsOpen(false)


    }

    return (
        <Modal
            open={isOpen}
            onClose={() => setIsOpen(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box className='deleteConfirmation' component="form" onSubmit={handleSubmit} width={"100%"} sx={style}>
                <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", bgcolor: "white", padding: "30px", borderRadius: "20px 0px 0px 20px", width: "100%" }}>


                    <Box display={"flex"} width={"100%"} gap={"15px"}>
                        {
                            textFields[1].map(item => {
                                if (item.type == "select") {
                                    return (
                                        <FormControl sx={{ width: "100%", mb: "10px" }}>
                                            <InputLabel>{item.placeholder}</InputLabel>
                                            <Select
                                                defaultValue={item.value}
                                                sx={{ width: "100%" }}
                                                label={item.placeholder}
                                                name={item.name}
                                                disabled={view}
                                            >
                                                {
                                                    item.options.map(val => (
                                                        <MenuItem value={val}>{val}</MenuItem>
                                                    ))
                                                }
                                            </Select>
                                        </FormControl>
                                    )
                                }
                                if (item.type == "date") {
                                    return (
                                        <TextField
                                            defaultValue={item.value}
                                            key={item.name}
                                            sx={{ margin: "10px 0" }}
                                            required
                                            fullWidth
                                            type={item.type || "text"}
                                            id={item.name}
                                            name={item.name}
                                            autoComplete={item.name}
                                            disabled={view}
                                        />
                                    )
                                }
                                return (
                                    <TextField
                                        defaultValue={item.value}
                                        key={item.name}
                                        multiline
                                        // sx={{ margin: "10px 0" }}
                                        required
                                        fullWidth
                                        type={item.type || "text"}
                                        id={item.name}
                                        label={item.placeholder}
                                        name={item.name}
                                        autoComplete={item.name}
                                        disabled={view}
                                    />
                                )
                            })
                        }
                    </Box>
                    <Box display={"flex"} width={"100%"} gap={"15px"}>
                        {
                            textFields[2].map(item => {
                                if (item.type == "select") {
                                    return (
                                        <FormControl sx={{ width: "100%", mb: "10px" }}>
                                            <InputLabel>{item.placeholder}</InputLabel>
                                            <Select
                                                defaultValue={item.value}
                                                sx={{ width: "100%" }}
                                                label={item.placeholder}
                                                name={item.name}
                                                disabled={view}
                                            >
                                                {
                                                    item.options.map(val => (
                                                        <MenuItem value={val}>{val}</MenuItem>
                                                    ))
                                                }
                                            </Select>
                                        </FormControl>
                                    )
                                }
                                if (item.type == "date") {
                                    return (
                                        <TextField
                                            defaultValue={item.value}
                                            key={item.name}
                                            sx={{ margin: "10px 0" }}
                                            required
                                            fullWidth
                                            type={item.type || "text"}
                                            id={item.name}
                                            name={item.name}
                                            autoComplete={item.name}
                                            disabled={view}
                                        />
                                    )
                                }

                                return (
                                    <TextField
                                        defaultValue={item.value}
                                        key={item.name}
                                        sx={{ margin: "10px 0" }}
                                        fullWidth
                                        type={item.type || "text"}
                                        id={item.name}
                                        label={item.placeholder}
                                        name={item.name}
                                        autoComplete={item.name}
                                        disabled={view}
                                    />
                                )
                            })
                        }
                    </Box>
                    <Box display={"flex"} width={"100%"} gap={"15px"}>
                        {
                            textFields[3].map(item => {
                                if (item.type == "select") {
                                    return (
                                        <FormControl sx={{ width: "100%", mb: "10px" }}>
                                            <InputLabel>{item.placeholder}</InputLabel>
                                            <Select
                                                defaultValue={item.value}
                                                sx={{ width: "100%" }}
                                                label={item.placeholder}
                                                name={item.name}
                                                disabled={view}
                                            >
                                                {
                                                    item.options.map(val => (
                                                        <MenuItem value={val}>{val}</MenuItem>
                                                    ))
                                                }
                                            </Select>
                                        </FormControl>
                                    )
                                }
                                if (item.type == "date") {
                                    return (
                                        <TextField
                                            defaultValue={item.value}
                                            key={item.name}
                                            sx={{ margin: "10px 0" }}
                                            required
                                            fullWidth
                                            type={item.type || "text"}
                                            id={item.name}
                                            name={item.name}
                                            autoComplete={item.name}
                                            disabled={view}
                                        />
                                    )
                                }

                                return (
                                    <TextField
                                        multiline
                                        defaultValue={item.value}
                                        key={item.name}
                                        sx={{ margin: "10px 0" }}
                                        required
                                        fullWidth
                                        type={item.type || "text"}
                                        id={item.name}
                                        label={item.placeholder}
                                        name={item.name}
                                        autoComplete={item.name}
                                        disabled={view}
                                    />
                                )
                            })
                        }
                    </Box>
                    <Box display={"flex"} width={"100%"} gap={"15px"}>
                        {
                            textFields[4].map(item => {
                                if (item.type == "select") {
                                    return (
                                        <FormControl sx={{ width: "100%", mb: "10px" }}>
                                            <InputLabel>{item.placeholder}</InputLabel>
                                            <Select
                                                defaultValue={item.value}
                                                sx={{ width: "100%" }}
                                                label={item.placeholder}
                                                name={item.name}
                                                disabled={view}
                                            >
                                                {
                                                    item.options.map(val => (
                                                        <MenuItem value={val}>{val}</MenuItem>
                                                    ))
                                                }
                                            </Select>
                                        </FormControl>
                                    )
                                }
                                if (item.type == "date") {
                                    return (
                                        <TextField
                                            multiline
                                            defaultValue={item.value}
                                            key={item.name}
                                            sx={{ margin: "10px 0" }}
                                            required
                                            fullWidth
                                            type={item.type || "text"}
                                            id={item.name}
                                            name={item.name}
                                            autoComplete={item.name}
                                            disabled={view}
                                        />
                                    )
                                }

                                return (
                                    <TextField
                                        multiline
                                        defaultValue={item.value}
                                        key={item.name}
                                        sx={{ margin: "10px 0" }}
                                        required
                                        fullWidth
                                        type={item.type || "text"}
                                        id={item.name}
                                        label={item.placeholder}
                                        name={item.name}
                                        autoComplete={item.name}
                                        disabled={view}
                                    />
                                )
                            })
                        }
                    </Box>
                    {!view && <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}
                    >
                        Submit
                    </Button>}
                </Box>


            </Box>
        </Modal >
    )
}


export default SheetInfoUpdate

function generateLogs(oldData, newData) {
    const logs = [];
    let logMessage = '';

    // Compare fields
    Object.keys(oldData).forEach(key => {
        if (key === '__v' || key === '_id') return; // Skip __v and _id, already handled
        if (oldData[key] !== newData[key]) {
            if (logMessage.length === 0) {
                logMessage = `${key} changed from "${oldData[key]}" to "${newData[key]}"`;
            } else {
                logMessage += `, ${key} changed from "${oldData[key]}" to "${newData[key]}"`;
            }
        }
    });

    if (logMessage.length > 0) {
        logs.push(logMessage);
    }

    return logs;
}
