import Checkbox from '@mui/material/Checkbox';
import { Box, Button, FormControl, IconButton, InputLabel, MenuItem, Modal, Pagination, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Tooltip, Typography } from '@mui/material'
import { useContext, useEffect, useMemo, useState } from 'react';
import axios from '../../../HTTP';
import { Context } from '../../../Context';
import UsersInfoCreate from './UsersInfoCreate';
import UsersInfoUpdate from './UsersInfoUpdate';
import EditNoteIcon from '@mui/icons-material/EditNote';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DeleteIcon from '@mui/icons-material/Delete';
import ReportIcon from '@mui/icons-material/Report';
import ListAltIcon from '@mui/icons-material/ListAlt';
import { Navigate, useNavigate, useParams, useSearchParams } from 'react-router-dom';
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
};
const tableCell = {
    border: "1px solid gray",
}
let getUniqueGroups = (groupsInfo) => {
    let obj = {};
    for (let i of groupsInfo) {
        obj[i.groupName] = 1;
    }
    let arr = [];
    for (let i in obj) {
        arr.push({ groupName: i })
    }
    return arr
}

const UsersInfo = () => {
    const { apiLink, selectAll, setSelectedRows, selectedRows, ActivateToast, isAdmin, token } = useContext(Context)
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);


    const [open, setOpen] = useState(false);
    const [usersInfo, setUsersInfo] = useState([])
    const [isAllSelected, setIsAllSelected] = useState(false)
    const [page, setPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(40);
    const [totalPages, setTotalPages] = useState(10);
    const [searchParams, setSearchParams] = useSearchParams()
    const [keyword, setKeyword] = useState(searchParams.get("keyword") || "");
    const [groupsInfo, setGroupsInfo] = useState([]);
    const [uniqueGroups, setUniqueGroups] = useState([]);


    const [totalCount, setTotalCount] = useState("");
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(1);
    };
    const handleDelete = (event) => {
        const selectedRowIDs = Object.keys(selectedRows);
        setUsersInfo(usersInfo.filter(item => !selectedRowIDs.includes(item._id)));
        for (let i in selectedRows) {
            selectedRows[i].handleDelete();
        }
        setSelectedRows([])
        setIsAllSelected(false)
        setIsDeleteModalOpen(false)
    };
    const handleUpdate = (event) => {
        if (Object.entries(selectedRows).length == 0) return ActivateToast("Please Select rows to update", "error")
        for (let i in selectedRows) {
            selectedRows[i].setIsSelected(false);
        }
        setSelectedRows([])
        setIsAllSelected(false)
        setSelectedRows({})
    };
    useEffect(() => {
        axios.get(`usersInfo?limit=${rowsPerPage}&page=${page}&keyword=${keyword}`).then(({ data }) => {
            setTotalCount(data.totalCount);
            setUsersInfo(data.data);
            setTotalPages(data.totalPages || 1);
        })
        axios.get("groupsInfo").then(({ data }) => {
            let tempData = data.sort((a, b) => {
                const nameA = a.groupName.toLowerCase();
                const nameB = b.groupName.toLowerCase();

                if (nameA < nameB) {
                    return -1;
                }
                if (nameA > nameB) {
                    return 1;
                }
                return 0;
            })
            setGroupsInfo(tempData)
            setUniqueGroups(getUniqueGroups(tempData))
        })
        for (let i in selectedRows) {
            selectedRows[i].setIsSelected(false);
        }
        setSelectedRows([])
        setIsAllSelected(false)
        setSelectedRows({})
    }, [page, rowsPerPage, keyword, apiLink, setSelectedRows])
    useEffect(() => {
        if (Object.entries(selectedRows).length === usersInfo.length) {
            setIsAllSelected(true)
        }
        else setIsAllSelected(false)
    }, [selectedRows, usersInfo])


    if (!token) return <Navigate to={`/login`} />
    return (
        <Box p={"30px"} display={"flex"} minHeight={"calc( 100vh - 60px )"} flexDirection={"column"} justifyContent={"space-between"}>
            <Box>
                <Box display={"flex"} flexDirection={"row-reverse"} justifyContent={"space-between"} alignItems={"center"} gap={"20px"}>
                    <Button onClick={() => setOpen(true)} sx={{ height: "50px", width: "276px", padding: "10px 0", borderRadius: "20px", textTransform: "none", bgcolor: "#57B657", color: "white", "&:hover": { bgcolor: "#66d466", color: "white" } }}>
                        Register New User
                    </Button>
                    <UsersInfoCreate uniqueGroups={uniqueGroups} groupsInfo={groupsInfo} setUsersInfo={setUsersInfo} state={[open, setOpen]} />
                </Box>
                <br />
                <Box display={"flex"} flexDirection={["column-reverse", "row", "row", "row"]} justifyContent={"space-between"} alignItems={"center"} gap={"20px"}>

                    <Box display={"flex"} alignItems={"center"} gap={"10px"}>
                        <FormControl sx={{ m: 1, minWidth: 80 }}>
                            <InputLabel id="demo-simple-select-autowidth-label">Rows Per Page</InputLabel>
                            <Select
                                labelId="demo-simple-select-autowidth-label"
                                id="demo-simple-select-autowidth"
                                value={rowsPerPage}
                                onChange={handleChangeRowsPerPage}
                                sx={{ width: "150px" }}
                                label="Rows Per Page"
                            >
                                <MenuItem value={10}>10</MenuItem>
                                <MenuItem value={40}>40</MenuItem>
                                <MenuItem value={60}>60</MenuItem>
                                <MenuItem value={80}>80</MenuItem>
                                <MenuItem value={100}>100</MenuItem>
                            </Select>
                        </FormControl>
                        {isAdmin && <Tooltip title="Delete"><Button onClick={() => {
                            if (Object.entries(selectedRows).length == 0) return ActivateToast("Please Select rows to Delete", "error")
                            setIsDeleteModalOpen(true)
                        }} sx={{ padding: "10px 25px", borderRadius: "20px", textTransform: "none", bgcolor: "#c6483f", color: "white", "&:hover": { bgcolor: "#c95f58", color: "white" } }}>
                            <DeleteIcon />
                        </Button></Tooltip>}
                    </Box>
                    <Modal
                        open={isDeleteModalOpen}
                        onClose={() => setIsDeleteModalOpen(false)}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                    >
                        <Box sx={style}>
                            <Box bgcolor={"white"} p={"20px"} borderRadius={"20px"}>
                                <Typography fontWeight={"700"} color={"gray"}>
                                    Do you want to delete these users ?
                                </Typography>
                                <br />
                                <Box display={"flex"} justifyContent={"space-evenly"} gap={"10px"}>
                                    <Button onClick={() => setIsDeleteModalOpen(false)} sx={{ flex: 1, padding: "10px 25px", borderRadius: "10px", textTransform: "none", bgcolor: "#008bda", color: "white", "&:hover": { bgcolor: "#0096ff", color: "white" } }}>Cancel</Button>
                                    <Button onClick={handleDelete} sx={{ flex: 1, padding: "10px 25px", borderRadius: "10px", textTransform: "none", bgcolor: "#c6483f", color: "white", "&:hover": { bgcolor: "#c95f58", color: "white" } }}>Delete</Button>
                                </Box>
                            </Box>
                        </Box>
                    </Modal>
                    <Box display={"flex"} gap={"10px"}>
                        <Button variant='contained' onClick={(e) => {
                            setKeyword("");
                            setSearchParams("")
                        }}>Reset</Button>
                        <TextField placeholder='Search' value={keyword} onChange={(e) => {
                            setKeyword(e.target.value)
                            setSearchParams({ keyword: e.target.value })
                        }} />
                    </Box>
                </Box>
                <TableContainer sx={{ width: "100%", maxHeight:"100vh" }}>
                    <Table aria-label="lead table" stickyHeader>
                        <TableHead>
                            <TableRow>

                                <TableCell sx={tableCell} ></TableCell>
                                <TableCell sx={tableCell} >Contact Name</TableCell>
                                <TableCell sx={tableCell} >Email</TableCell>
                                <TableCell sx={tableCell} >WT Username</TableCell>
                                <TableCell sx={tableCell} >Auth Domain</TableCell>
                                {isAdmin && <TableCell sx={tableCell} >
                                    <Checkbox checked={Object.entries(selectedRows).length == usersInfo.length} onChange={() => {
                                        setIsAllSelected(!isAllSelected);
                                        for (let i of selectAll) {
                                            if (!isAllSelected) {
                                                i.setIsSelected(true)
                                            }
                                            else {
                                                i.setIsSelected(false)
                                            }
                                        }
                                    }} />
                                </TableCell>}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                (usersInfo || []).map((item, ind) => (
                                    <TableDataRow key={item._id} uniqueGroups={uniqueGroups} groupsInfo={groupsInfo} bg={ind % 2 == 0 ? "#efefef" : "white"} data={item} apiLink={apiLink} usersInfo={usersInfo} setUsersInfo={setUsersInfo} />
                                ))
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
                <br />
                <Box>
                    Showing {page * rowsPerPage - rowsPerPage + 1} to {page * rowsPerPage - rowsPerPage + usersInfo.length} of {totalCount}
                </Box>
                <br />
            </Box>
            <Box display={"flex"} justifyContent={"center"}>
                <Pagination count={totalPages} page={page} onChange={handleChangePage} />
            </Box>
        </Box>
    )
}

function TableDataRow({ data, groupsInfo, usersInfo, setUsersInfo, bg, uniqueGroups }) {
    const navigate = useNavigate()
    const [params] = useSearchParams()
    const [isOpen, setIsOpen] = useState(false);

    const [isSelected, setIsSelected] = useState(false)
    const [count, setCount] = useState(1);
    const { setSelectAll, setSelectedRows, isAdmin, permissions, PostToLogs } = useContext(Context);
    const [isUpdateModalOpen, setIsUpdateModalOpen] = useState((params.get("editModal") && usersInfo.length==1) ? true : false);
    const handleUpdate = () => {
        setIsUpdateModalOpen(true)
    }
    const handleDelete = () => {
        let temp = usersInfo.filter(item => item._id != data._id);
        setUsersInfo(temp)
        axios.delete(`usersInfo/${data._id}`);
        let temp1 = usersInfo.find(item => item._id == data._id);
        PostToLogs({
            type: "User Delete",
            activity: `User Deleted - ${temp1.fullName}`
        })
    }
    useEffect(() => {
        setSelectAll(prev => [...prev, {
            setIsSelected, handleDelete
        }])
    }, [])
    useEffect(() => {
        if (count == 1) return setCount(2);
        if (isSelected) {
            setSelectedRows(prev => {
                return {
                    ...prev, [data._id]: {
                        setIsSelected, handleDelete: () => {
                            axios.delete(`usersInfo/${data._id}`)
                        }
                    }
                }
            })
        }
        else {
            setSelectedRows(prev => {
                delete prev[data._id];
                return { ...prev };
            })
        }
    }, [isSelected])

    return (
        <TableRow sx={{ bgcolor: isSelected ? "#cae2ff" : bg, }}>

            <TableCell sx={tableCell}>
                <Box sx={{ display: "flex", gap: "5px" }}>
                    <Tooltip title={(isAdmin || permissions.edit) ? "Edit" : "View"}>
                        <IconButton onClick={handleUpdate} sx={{ bgcolor: "#57B657", color: "white", "&:hover": { bgcolor: "#66d466", color: "white" } }}>
                            {(isAdmin || permissions.edit) && <EditNoteIcon />}
                            {(!isAdmin && !permissions.edit) && <VisibilityIcon />}
                        </IconButton>
                    </Tooltip>
                    {isUpdateModalOpen && <UsersInfoUpdate uniqueGroups={uniqueGroups} setUsersInfo={setUsersInfo} groupsInfo={groupsInfo} _id={data._id} data={data} state={[isUpdateModalOpen, setIsUpdateModalOpen]} view={(!isAdmin && !permissions.edit)} />}
                    {isAdmin && <>
                        <Tooltip title="Delete"><IconButton onClick={() => setIsOpen(true)} sx={{ bgcolor: "#c6483f", color: "white", "&:hover": { bgcolor: "#c95f58", color: "white" } }}>
                            <DeleteIcon />
                        </IconButton>
                        </Tooltip>
                        <Modal
                            open={isOpen}
                            onClose={() => setIsOpen(false)}
                            aria-labelledby="modal-modal-title"
                            aria-describedby="modal-modal-description"
                        >
                            <Box sx={style}>
                                <Box bgcolor={"white"} p={"20px"} borderRadius={"20px"}>
                                    <Typography fontWeight={"700"} color={"gray"}>
                                        Do you want to delete this user ?
                                    </Typography>
                                    <br />
                                    <Box display={"flex"} justifyContent={"space-evenly"} gap={"10px"}>
                                        <Button onClick={() => setIsOpen(false)} sx={{ flex: 1, padding: "10px 25px", borderRadius: "10px", textTransform: "none", bgcolor: "#008bda", color: "white", "&:hover": { bgcolor: "#0096ff", color: "white" } }}>Cancel</Button>
                                        <Button onClick={handleDelete} sx={{ flex: 1, padding: "10px 25px", borderRadius: "10px", textTransform: "none", bgcolor: "#c6483f", color: "white", "&:hover": { bgcolor: "#c95f58", color: "white" } }}>Delete</Button>
                                    </Box>
                                </Box>
                            </Box>
                        </Modal>
                    </>}
                    <Tooltip title="Reports">
                        <IconButton onClick={() => navigate(`${isAdmin ? "/admin" : "/client"}/reports/${data._id}`)} sx={{ bgcolor: "#c6483f", color: "white", "&:hover": { bgcolor: "#c95f58", color: "white" } }}>
                            <ReportIcon />
                        </IconButton>
                    </Tooltip>

                    <Tooltip title="Sheets Used">
                        <IconButton onClick={() => navigate(`${isAdmin ? "/admin" : "/client"}/sheetsUsed/${data._id}`)} sx={{ bgcolor: "teal", color: "white", "&:hover": { bgcolor: "teal", color: "white" } }}>
                            <ListAltIcon />
                        </IconButton>
                    </Tooltip>

                </Box>
            </TableCell>
            <TableCell sx={tableCell}>{data?.contactName}</TableCell>
            <TableCell sx={tableCell}>{data?.email}</TableCell>
            <TableCell sx={tableCell}>{data?.whatsAppUsers}</TableCell>
            <TableCell sx={tableCell}>{data?.domains.join(", ")}</TableCell>
            {isAdmin && <TableCell sx={tableCell}>
                <Checkbox checked={isSelected} onChange={() => setIsSelected(!isSelected)} />
            </TableCell>}
        </TableRow>
    )
}

export default UsersInfo;