import { Autocomplete, Box, Button, FormControl, InputLabel, MenuItem, Modal, Select, TextField, Typography } from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'
import { Context } from '../../../Context';
import axios from '../../../HTTP';
import { uid } from 'uid';
import DeleteIcon from '@mui/icons-material/Delete';
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: ["100vw", "80vw"],
    maxHeight: ["80vh"],
    borderRadius: "20px 0px 0px 20px",
    overflowY: "scroll"
};

function dateNow() {
    const tempDate = new Date()
    return tempDate.getFullYear() + "-" + (tempDate.getMonth() < 10 ? "0" + (tempDate.getMonth() + 1) : + tempDate.getMonth() + 1) + "-" + (tempDate.getDate() < 10 ? "0" + (tempDate.getDate() + 1) : + tempDate.getDate() + 1)
}

const GroupsInfoUpdate = ({ state, setGroupsInfo, data, _id, view, sheetIDS }) => {
    const [isOpen, setIsOpen] = state;
    const { ActivateToast, PostToLogs } = useContext(Context);
    const [sheets, setSheets] = useState(data.sheets.map(item => {
        item.id = uid();
        return { ...item }
    }) || [
            { sheetID: "", licenseVersion: "BASIC", creditsAllowed: "" }
        ])
    const [alias, setAlias] = useState(data.alias || "")
    const [filteredSheets, setFilteredSheets] = useState([])
    const [keyword, setKeyword] = useState("")
    const [loading, setLoading] = useState(true);
    const textFields = [
        { name: "groupName", placeholder: "Group's Name", defaultValue: data.groupName },
    ]
    const [validity, setValidity] = useState(data.validity);
    const handleSubmit = (event) => {
        event.preventDefault()
        const formData = new FormData(event.currentTarget);
        let postData = { _id, validity: validity, subscriptionValidity: formData.get("subscriptionValidity"), }
        for (let i of textFields) {
            postData[i.name] = formData.get(i.name);
        }
        postData.sheets = sheets;
        postData.alias = alias;
        axios.patch(`groupsInfo/${_id}`, postData)
        setGroupsInfo(prev => {
            let temp = [...prev];
            for (let i = 0; i < temp.length; i++) {
                if (temp[i]._id === _id) {
                    temp[i] = postData;
                    break
                }
            }
            return temp;
        })

        let logs = generateLogs(data, postData);
        if (logs.length > 0) {
            PostToLogs({
                type: "Group Update",
                activity: `${logs.join(", ")} , Group Updated - ${postData.groupName}`
            })
        }

        ActivateToast("Data Updated", "success");
        setIsOpen(false)
    }


    useEffect(() => {
        if (isOpen) setLoading(false)
    }, [isOpen])

    useEffect(() => {
        if (keyword == "") setFilteredSheets([...sheets])
        setFilteredSheets(sheets.filter((item) => {
            return item.sheetID.toLowerCase().includes(keyword) || item.licenseVersion.toLowerCase().includes(keyword)
        }))
    }, [keyword])

    return (
        <Modal
            open={isOpen}
            onClose={() => setIsOpen(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box className='deleteConfirmation' component="form" onSubmit={handleSubmit} width={"100%"} sx={style}>
                <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", bgcolor: "white", padding: "30px", borderRadius: "20px 0px 0px 20px", width: "100%" }}>
                    {!view && <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ mt: 1, mb: 3 }}
                    >
                        Submit
                    </Button>}
                    <Box display={"flex"} width={"100%"} gap={"15px"}>
                        {
                            textFields.map(item => (
                                <TextField
                                    key={item.placeholder}
                                    // sx={{ margin: "10px 0" }}
                                    required
                                    fullWidth
                                    id={item.name}
                                    label={item.placeholder}
                                    name={item.name}
                                    autoComplete={item.name}
                                    defaultValue={item.defaultValue}
                                    disabled={view}
                                />
                            ))
                        }
                        <FormControl fullWidth>
                            <InputLabel>Subscription Validity</InputLabel>
                            <Select
                                // sx={{ width: "100%" }}
                                label={"Subscription Validity"}
                                name='subscriptionValidity'
                                defaultValue={data?.subscriptionValidity || ""}
                            // onChange={handleChange}
                            >
                                <MenuItem value={"0.5"}>6 Months</MenuItem>
                                <MenuItem value={"1"}>1 Year</MenuItem>
                                <MenuItem value={"2"}>2 Years</MenuItem>
                                <MenuItem value={"5"}>5 Years</MenuItem>
                                <MenuItem value={"10"}>10 Years</MenuItem>
                                <MenuItem value={"20"}>20 Years</MenuItem>
                                <MenuItem value={"30"}>30 Years</MenuItem>
                                <MenuItem value={"40"}>40 Years</MenuItem>
                            </Select>
                        </FormControl>
                        <FormControl fullWidth sx={{ width: "100%" }}  >
                            <InputLabel>Validity</InputLabel>
                            <Select
                                disabled={view}
                                defaultValue={validity}
                                onChange={(e) => setValidity(e.target.value)}
                                sx={{ width: "100%" }}
                                label={"Validity"}
                                name="validity"
                            >
                                <MenuItem value={"0.5"}>6 Months</MenuItem>
                                <MenuItem value={"1"}>1 Year</MenuItem>
                                <MenuItem value={"2"}>2 Years</MenuItem>
                                <MenuItem value={"5"}>5 Years</MenuItem>
                                <MenuItem value={"10"}>10 Years</MenuItem>
                                <MenuItem value={"20"}>20 Years</MenuItem>
                                <MenuItem value={"30"}>30 Years</MenuItem>
                                <MenuItem value={"40"}>40 Years</MenuItem>
                            </Select>
                        </FormControl>

                        <TextField
                            value={alias}
                            onChange={(e) => setAlias(e.target.value)}
                            required
                            fullWidth
                            label={"Alias"}
                        />
                    </Box>
                    <br />
                    <Box width={"100%"} display={"flex"} flexDirection={"column"} gap={"10px"}>
                        {!view &&
                            <Box display={"flex"} justifyContent={"space-between"}>
                                <Button variant='contained' sx={{ width: "9%", textTransform: "none", color: "white" }} onClick={() => {
                                    let temp = JSON.parse(JSON.stringify(sheets));
                                    setSheets([{ sheetID: "", licenseVersion: "BASIC", creditsAllowed: "", id: uid() }, ...temp])
                                }}>Add</Button>
                                {/* <Box display={"flex"} gap={"10px"} >
                                    <Button variant='contained' onClick={() => setKeyword("")}>Reset</Button>
                                    <TextField label={"Search"} value={keyword} onChange={(e) => setKeyword(e.target.value.toLowerCase())} />
                                </Box> */}
                                <Search keyword={keyword} setKeyword={setKeyword} />
                            </Box>
                        }
                        <Box display={"flex"} justifyContent={"space-between"}>
                            <Typography width={"45%"}>Sheet ID</Typography>
                            <Typography width={"20%"}>License Version</Typography>
                            <Typography width={"20%"}>Credits Allowed</Typography>
                            {!view && <Typography width={"9%"} ></Typography>}
                        </Box>
                        {!loading &&
                            (keyword == "" ? sheets : filteredSheets).map((item, index) => (
                                <GroupTable key={item.id} view={view} index={index} data={item} setSheets={setSheets} sheetIDS={sheetIDS} />
                            ))
                        }
                        <br />
                    </Box>

                </Box>
            </Box>
        </Modal >
    )
}


function GroupTable({ data, index, setSheets, sheetIDS, view }) {
    function handleChange({ target }) {
        const { value, name } = target;
        setSheets(prev => {
            let tempGroups = [...prev];
            for (let i = 0; i < tempGroups.length; i++) {
                if (tempGroups[i].id == data.id) {
                    tempGroups[i][name] = value;
                    return tempGroups
                }
            }
        })
    }

    function handleAutoCompleteChange({ sheetID }) {
        setSheets(prev => {
            let tempGroups = [...prev];
            for (let i = 0; i < tempGroups.length; i++) {
                if (tempGroups[i].id == data.id) {
                    tempGroups[i].sheetID = sheetID;
                    return tempGroups
                }
            }
        })
    }

    return (
        <Box display={"flex"} justifyContent={"space-between"}>
            <Autocomplete
                sx={{ width: "45%" }}
                options={sheetIDS} // Use complete objects as options
                getOptionLabel={(option) => {
                    let temp = sheetIDS.find(it => it.sheetID == option.sheetID);
                    return option.sheetID ? `${option.sheetID} - ${temp?.description || "Sheet does not exist in DB."}` : ""
                }} // Display question text
                onChange={(e, newValue) => {
                    if (newValue) {
                        handleAutoCompleteChange(newValue);
                    }
                }}
                isOptionEqualToValue={(option, value) => {
                    return option.sheetID == value.sheetID
                }}
                disabled={view}
                defaultValue={data}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label="Search Sheet"
                        InputProps={{
                            ...params.InputProps,
                            type: "search",
                        }}
                    />
                )}
            />
            <Select disabled={view} sx={{ width: "20%" }} value={data.licenseVersion} onChange={handleChange} name='licenseVersion' >
                <MenuItem value={"BASIC"}>BASIC</MenuItem>
                <MenuItem value={"PRO"}>PRO</MenuItem>
            </Select>
            <TextField disabled={view} value={data.creditsAllowed} sx={{ width: "20%" }} type='number' onChange={handleChange} name='creditsAllowed' />
            {!view && <Button onClick={() => {
                setSheets(prevGroups => {
                    if (prevGroups.length == 1) return prevGroups;
                    let tempArr = [];
                    for (let i = 0; i < prevGroups.length; i++) {
                        if (index == i) continue;
                        tempArr.push(prevGroups[i])
                    }
                    return JSON.parse(JSON.stringify(tempArr))
                })
            }} sx={{ width: "9%", borderRadius: "20px", textTransform: "none", bgcolor: "red", color: "white", "&:hover": { bgcolor: "#ff3a3a", color: "white" } }}><DeleteIcon /></Button>}
        </Box>
    )
}




export default GroupsInfoUpdate



function Search({ keyword, setKeyword }) {
    return <Box display={"flex"} gap={"10px"} >
        <Button variant='contained' onClick={() => setKeyword("")}>Reset</Button>
        <TextField label={"Search"} value={keyword} onChange={(e) => setKeyword(e.target.value.toLowerCase())} />
    </Box>
}

function generateLogs(oldData, newData) {
    const logs = [];
    let logMessage = '';

    // Compare sheets
    oldData.sheets.forEach(oldSheet => {
        const newSheet = newData.sheets.find(sheet => sheet.sheetID === oldSheet.sheetID);
        if (!newSheet) {
            logs.push(`${oldSheet.sheetID} sheet removed`);
        } else if (newSheet.licenseVersion !== oldSheet.licenseVersion || newSheet.creditsAllowed !== oldSheet.creditsAllowed) {
            logs.push(`${oldSheet.sheetID} sheet licenseVersion/creditsAllowed changed`);
        }
    });

    newData.sheets.forEach(newSheet => {
        const oldSheet = oldData.sheets.find(sheet => sheet.sheetID === newSheet.sheetID);
        if (!oldSheet) {
            logs.push(`${newSheet.sheetID} sheet added`);
        }
    });

    // Compare validity
    if (oldData.validity !== newData.validity) {
        logs.push(`Validity changed from "${oldData.validity}" to "${newData.validity}"`);
    }

    // Compare other fields
    Object.keys(oldData).forEach(key => {
        if (key === 'sheets' || key === '__v' || key === '_id') return; // Skip sheets, __v, and _id, already handled
        if (oldData[key] !== newData[key]) {
            if (logMessage.length === 0) {
                logMessage = `${key} changed from "${oldData[key]}" to "${newData[key]}"`;
            } else {
                logMessage += `, ${key} changed from "${oldData[key]}" to "${newData[key]}"`;
            }
        }
    });

    if (logMessage.length > 0) {
        logs.push(logMessage);
    }

    return logs;
}
